import React from 'react'
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
} from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { useFormikContext } from 'formik'
import { JobLocationStepFormValues } from '@local/Features/WizardSteps/JobLocationStep/Types/formTypes'
import { LocationCheckbox } from '@local/Features/WizardSteps/JobLocationStep/Components/LocationCheckbox/LocationCheckbox'

export const ForeignCountryForm: React.FC = () => {
  const { foreignWorkLocation } = usePickEpiContent().wizardVarOchHur

  const { values } = useFormikContext<JobLocationStepFormValues>()

  return (
    <FormControl>
      <Typography variant="subtitle1" color="textPrimary" component={FormLabel}>
        {foreignWorkLocation.heading}
      </Typography>
      <LocationCheckbox
        checked={values.foreignLocations.some(
          (option) => option.name === 'Danmark'
        )}
        searchTerm="Danmark"
        label={foreignWorkLocation.danmarkOption}
      />
      <LocationCheckbox
        checked={values.foreignLocations.some(
          (option) => option.name === 'Norge'
        )}
        searchTerm="Norge"
        label={foreignWorkLocation.norgeOption}
      />
      <FormHelperText>{foreignWorkLocation.preamble}</FormHelperText>
    </FormControl>
  )
}
