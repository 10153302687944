import React from 'react'
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { urlRegEx } from '@local/Utils/Helpers/regexes'
import { Clear, OpenInNew } from '@mui/icons-material'

interface LinkTextFieldProps extends TextFieldProps<'outlined'> {
  valueRegex: RegExp
  hasErrors?: boolean
  linkTestId?: string
  buttonAriaLabel?: string
  disableTracking?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClear?: () => void
}

export const LinkTextField = ({
  hasErrors,
  valueRegex,
  linkTestId,
  value,
  buttonAriaLabel,
  disableTracking = false,
  onChange,
  onClear,
  ...props
}: LinkTextFieldProps) => {
  const hasInput = () => value !== '' && value !== undefined
  const convertToHttp = (url: string) =>
    (value as string)?.match(urlRegEx) ? url : `https://${url}`

  return (
    <TextField
      onChange={onChange}
      value={value}
      InputProps={{
        endAdornment: !hasErrors &&
          hasInput() &&
          (value as string)?.match(valueRegex) && (
            <InputAdornment position="end">
              <Stack direction="row" spacing={0} alignItems="center">
                {onClear && value && (
                  <IconButton onClick={onClear}>
                    <Clear fontSize="small" />
                  </IconButton>
                )}
                <Link
                  to={{ pathname: convertToHttp(value as string) }}
                  target="_blank"
                  data-testid={linkTestId}
                  data-trackable={disableTracking ? 'false' : 'true'}
                >
                  <IconButton aria-label={buttonAriaLabel} edge="end">
                    <OpenInNew fontSize="small" />
                  </IconButton>
                </Link>
              </Stack>
            </InputAdornment>
          ),
      }}
      {...props}
    />
  )
}
