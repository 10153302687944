import React from 'react'
import { CvPdf } from '@local/Types'
import { useFormikContext } from 'formik'
import { CircularProgress, List, ListSubheader, Stack } from '@mui/material'
import { useAuthentication } from '@trr/app-shell-data'

import { ExperienceStepFormValues } from '../../Types/experienceStepFormTypes'
import { useGetCvListQuery } from '../../Api/cvApi'

import { CvListItem } from './CvRadioItem'

interface CvRadioListProps {
  toggleModal: () => void
}

export const CvList: React.FC<CvRadioListProps> = ({ toggleModal }) => {
  const { setFieldValue } = useFormikContext<ExperienceStepFormValues>()
  const { sub } = useAuthentication()
  const { data, isLoading } = useGetCvListQuery(sub)

  const handleChange = (cv: CvPdf) => {
    void setFieldValue('cvPdf', cv)
    toggleModal()
  }

  return (
    <List dense>
      <ListSubheader>Dina uppladdade CV:n</ListSubheader>

      {isLoading ? (
        <Stack alignItems={'center'} py={5}>
          <CircularProgress />
        </Stack>
      ) : (
        data.map((cv) => (
          <CvListItem onChange={handleChange} cv={cv} key={cv.id} />
        ))
      )}
    </List>
  )
}
