import React from 'react'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { useGetWizardStep } from '@local/Utils/Hooks/wizard'
import { WizardStepPaths } from '@local/Router/routes'
import {
  employmentExtentConverter,
  employmentTypeConverter,
} from '@local/Utils/Helpers/locationToChip'
import { useGetProfileQuery } from '@local/Api/profileApi'
import { useAuthentication } from '@trr/app-shell-data'

import ProfileChipBlock from '../ProfileChipBlock/ProfileChipBlock'
import ProfileSection from '../ProfileSection/ProfileSection'

const TypeOfEmploymentBlock: React.FC = () => {
  const { getWizardUrlStep } = useGetWizardStep()
  const { sub } = useAuthentication()
  const { data: profile } = useGetProfileQuery(sub)

  const { title, linkLabel, chipEmploymentExtent, chipEmploymentType } =
    usePickEpiContent().profile.employmentExtent

  const employmentTypeList =
    profile?.additionalInformation.formsOfEmployment.map((employmentType) => ({
      name: employmentTypeConverter(employmentType),
      id: employmentType,
    }))

  const extentOfEmploymentList =
    profile?.additionalInformation.extentsOfEmployment.map(
      (extentOfEmployment) => ({
        name: employmentExtentConverter(extentOfEmployment),
        id: extentOfEmployment,
      })
    )

  return (
    <ProfileSection
      title={title}
      changeButtonProps={{
        title: linkLabel,
        href: getWizardUrlStep(WizardStepPaths.JobTypes, true),
      }}
      data-testid="employment-block"
    >
      <ProfileChipBlock title={chipEmploymentType} chips={employmentTypeList} />
      <ProfileChipBlock
        title={chipEmploymentExtent}
        chips={extentOfEmploymentList}
      />
    </ProfileSection>
  )
}

export default TypeOfEmploymentBlock
