import {
  JobTitle,
  LocationInformation,
  FormsOfEmployment,
  ExtentsOfEmployment,
  OtherPreferences,
  PatchCvPdf,
} from '@local/Types'

export enum PatchOps {
  Replace = 'replace',
  Add = 'add',
}

const PatchPaths = {
  Competences: 'competences',
  Jobtitles: 'jobTitles',
  WorkPlaceType: 'additionalInformation/otherPreferences',
  Location: 'locationinformations',
  EmploymentForm: 'additionalInformation/formsOfEmployment',
  EmploymentExtent: 'additionalInformation/extentsOfEmployment',
  CompletedWizard: 'completedWizard',
  Cv: 'documents',
} as const

type ReplacePaths = typeof PatchPaths
type ReplacePathKeys = keyof ReplacePaths
type AddPathKeys = Extract<
  ReplacePathKeys,
  'Competences' | 'Jobtitles' | 'Location'
>

type Patch<T> = {
  op: PatchOps
  path: string
  value: T
}

interface PatchReplaceApiModel<PathKey extends ReplacePathKeys, ValueType>
  extends Patch<ValueType> {
  op: PatchOps.Replace
  path: ReplacePaths[PathKey]
  value: ValueType
}

interface PatchAddApiModel<PathKey extends AddPathKeys, ValueType>
  extends Patch<ValueType> {
  op: PatchOps.Add
  path: `${ReplacePaths[PathKey]}/-`
  value: ValueType
}

export type PatchApiModel<
  PathKey extends keyof typeof PatchPaths,
  ReplaceType,
> =
  | PatchReplaceApiModel<PathKey, ReplaceType>
  | (PathKey extends AddPathKeys
      ? PatchAddApiModel<
          PathKey,
          ReplaceType extends unknown[] ? ReplaceType[0] : ReplaceType
        >
      : never)

export type PatchApiInput =
  | PatchApiModel<'Competences', string[]>
  | PatchApiModel<'Jobtitles', JobTitle[]>
  | PatchApiModel<'Location', LocationInformation[]>
  | PatchApiModel<'EmploymentForm', FormsOfEmployment[]>
  | PatchApiModel<'EmploymentExtent', ExtentsOfEmployment[]>
  | PatchApiModel<'WorkPlaceType', OtherPreferences[]>
  | PatchApiModel<'Cv', PatchCvPdf[]>
  | PatchApiModel<'CompletedWizard', boolean>
