import { baseApi } from '@local/Api/baseApi'
import { LocationInformation } from '@local/Types'

export interface GetLocationsResponse extends LocationInformation {
  nameWithHighLightedSearchTerm: string
}

export const locationsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getLocations: builder.query<LocationInformation[], string>({
      query: (searchText) => ({
        url: `/referencedata/locations/suggest/${searchText}`,
      }),
      transformResponse: (baseQueryReturnValue: GetLocationsResponse[]) =>
        baseQueryReturnValue.map((item) => ({
          id: item.id,
          name: item.name,
          type: item.type,
          locatedIn: item.locatedIn,
          nameWithHighLightedSearchTerm: item.nameWithHighLightedSearchTerm,
        })),
    }),
  }),
})

export const { useLazyGetLocationsQuery } = locationsApi
