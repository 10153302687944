import React from 'react'
import { withFormik } from 'formik'

import { JobTypePage } from '../JobTypePage/JobTypePage'
import {
  JobTypeStepFormProps,
  JobTypeStepFormValues,
} from '../../Types/formTypes'

import { jobTypeStepValidationSchema } from './JobTypeForm.schema'

const JobTypeStepForm: React.FC = () => <JobTypePage />

export const JobTypeFormik = withFormik<
  JobTypeStepFormProps,
  JobTypeStepFormValues
>({
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: ({ profileJobTypes }) => ({
    extentsOfEmployment: profileJobTypes.extentsOfEmployment,
    formsOfEmployment: profileJobTypes.formsOfEmployment,
  }),
  validationSchema: ({ content }: JobTypeStepFormProps) =>
    jobTypeStepValidationSchema(content),
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  displayName: 'JobTypeStepForm',
})(JobTypeStepForm)
