import React from 'react'
import { omit } from 'lodash'
import { useWizardNavigation } from '@local/Utils/Hooks/wizard'
import { isEditingOrUpdating } from '@local/Utils/Helpers/form.helpers'
import { WizardStep } from '@trr/wizard-library'
import { WizardStepProps } from '@trr/wizard-library/lib/@types/WizardStep/WizardStep'
import { WizardModes } from '@local/Types/App.types'
import { useWizardGtmTracker } from '@local/Utils/Hooks/gtm'
import { Stack } from '@mui/material'
import { WizardStepHeader } from '@local/Components/WizardStepHeader/WizardStepHeader'

export const ProfileWizardStep: React.FC<WizardStepProps> = (props) => {
  const isEditing = isEditingOrUpdating()
  const { cancel } = useWizardNavigation()

  useWizardGtmTracker(isEditing ? WizardModes.Editing : WizardModes.Creating)

  if (isEditing) {
    props = {
      ...props,
      stepInfo: { ...props.stepInfo, nextStepLabel: '' },
      progressBar: null,
      nextButton: {
        text: 'Spara',
        ...props.nextButton,
      },
      prevButton: { text: 'Avbryt', onClick: cancel },
    }
  }

  return (
    <WizardStep
      {...omit(props, ['children', 'stepInfo'])}
      disableDefaultTracking
    >
      <Stack spacing={5}>
        {props.stepInfo && (
          <WizardStepHeader
            title={props.stepInfo.currentStepLabel}
            subtitle={
              props.stepInfo.nextStepLabel
                ? 'Nästa: ' + props.stepInfo.nextStepLabel
                : undefined
            }
          />
        )}
        {props.children}
      </Stack>
    </WizardStep>
  )
}
