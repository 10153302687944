import { withFormik } from 'formik'
import React from 'react'

import { JobtitlesStep } from '../JobtitleStep/JobtitleStep'
import {
  JobtitleStepFormProps,
  JobtitleStepFormValues,
} from '../../Types/formTypes'

import { jobttitleStepValidationSchema } from './JobtitleStepForm.schema'

const JobtitleStepForm: React.FC = () => <JobtitlesStep />

export const JobtitleStepFormik = withFormik<
  JobtitleStepFormProps,
  JobtitleStepFormValues
>({
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: ({ profileJobtitles }) => ({ jobtitles: profileJobtitles }),
  validationSchema: ({ content }: JobtitleStepFormProps) =>
    jobttitleStepValidationSchema(content),
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  displayName: 'JobtitleStepForm',
})(JobtitleStepForm)
