import React from 'react'
import { ChipProps, Chip } from '@mui/material'
import { Check } from '@mui/icons-material'

interface SelectableChipProps extends ChipProps {
  isSelected: boolean
  onClick: ChipProps['onClick']
}

const selectedChipProps: ChipProps = {
  variant: 'filled',
  color: 'success',
  icon: <Check />,
  'aria-selected': true,
}

const unselectedChipProps: ChipProps = {
  variant: 'outlined',
  'aria-selected': false,
}

export const SelectableChip: React.FC<SelectableChipProps> = ({
  isSelected,
  ...props
}) => (
  <Chip
    {...(isSelected ? selectedChipProps : unselectedChipProps)}
    {...props}
  />
)
