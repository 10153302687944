import { ExtentsOfEmployment, FormsOfEmployment } from '@local/Types'
import { array, object, mixed, AnySchema } from 'yup'

import { JobTypeStepContent } from '../../Types/content'
import { JobTypeStepFormValues } from '../../Types/formTypes'

export const jobTypeStepValidationSchema = (
  content: JobTypeStepContent
): AnySchema<JobTypeStepFormValues> =>
  object().shape({
    formsOfEmployment: array(
      mixed<FormsOfEmployment>().oneOf(Object.values(FormsOfEmployment))
    )
      .min(1, content.jobType.warning)
      .ensure(),
    extentsOfEmployment: array(
      mixed<ExtentsOfEmployment>().oneOf(Object.values(ExtentsOfEmployment))
    )
      .min(1, content.jobPartOrFullTime.warning)
      .ensure(),
  })
