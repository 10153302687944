import { baseApi } from '@local/Api/baseApi'
import { PatchApiInput } from '@local/Types/patchTypes'

interface PatchProfileRequest {
  body: PatchApiInput[]
  clientId: string
}

export const patchProfileApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    patchProfile: builder.mutation<void, PatchProfileRequest>({
      query: ({ body, clientId }) => ({
        url: `/client-match/profiles/${clientId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Profile', 'Suggestions'],
    }),
  }),
})

export const { usePatchProfileMutation } = patchProfileApi
