import { baseApi } from '@local/Api/baseApi'
import { JobTitle } from '@local/Types'

interface GetJobtitlesResponse {
  id: string
  jobTitle: string
  jobTitleWithHighLightedSearchTerm: string
}

export const jobtitleApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getJobtitles: builder.query<JobTitle[], string>({
      query: (searchText) => ({
        url: `/referencedata/jobtitles/v2/suggestions/${searchText}`,
      }),
      transformResponse: (baseQueryReturnValue: GetJobtitlesResponse[]) =>
        baseQueryReturnValue.map((item) => ({
          id: item.id,
          name: item.jobTitle,
          nameWithHighLightedSearchTerm: item.jobTitleWithHighLightedSearchTerm,
        })),
    }),
  }),
})

export const { useLazyGetJobtitlesQuery } = jobtitleApi
