import React from 'react'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { WizardStepPaths } from '@local/Router/routes'
import { useGetWizardStep } from '@local/Utils/Hooks/wizard'
import { useAuthentication } from '@trr/app-shell-data'
import { useGetProfileQuery } from '@local/Api/profileApi'

import ProfileChipBlock from '../ProfileChipBlock/ProfileChipBlock'
import ProfileSection from '../ProfileSection/ProfileSection'

const CompetenceBlock: React.FC = () => {
  const { getWizardUrlStep } = useGetWizardStep()
  const { sub } = useAuthentication()
  const { data: profile } = useGetProfileQuery(sub)
  const { title, linkLabel } = usePickEpiContent().profile.competences

  const competencesChipList = profile?.competences.map((c) => ({
    id: c,
    name: c,
  }))

  return (
    <ProfileSection
      title={title}
      changeButtonProps={{
        title: linkLabel,
        href: getWizardUrlStep(WizardStepPaths.Kunskaper, true),
      }}
      data-testid="competences-block"
    >
      <ProfileChipBlock chips={competencesChipList} />
    </ProfileSection>
  )
}

export default CompetenceBlock
