import { useWizard } from '@trr/wizard-library'

import { useClickGtmTracker } from './useClickGtmTracker'

export const useNavigationGtmTracker = () => {
  const { trackButtonClick } = useClickGtmTracker()
  const { activeStep } = useWizard()

  const trackInvalidFormSubmit = () => {
    trackButtonClick('button_click_next_invalid', {
      label: `Step ${activeStep}: Nästa`,
    })
  }

  const trackValidFormSubmit = (href: string) => {
    trackButtonClick('button_click_next', {
      label: `Step ${activeStep}: Nästa`,
      href,
    })
  }

  const trackGoBack = (href: string) => {
    trackButtonClick('button_click_back', {
      label: `Step ${activeStep}: Tillbaka`,
      href,
    })
  }

  const trackCancel = () => {
    trackButtonClick('button_click_cancel', {
      label: `Step ${activeStep}: Avbryt`,
    })
  }

  const trackSave = () => {
    trackButtonClick('button_click_save', {
      label: `Step ${activeStep}: Spara`,
    })
  }

  return {
    trackInvalidFormSubmit,
    trackValidFormSubmit,
    trackGoBack,
    trackCancel,
    trackSave,
  }
}

export default useNavigationGtmTracker
