import { WizardQueryParams } from '@local/Router/routes'
import { hasUrlParam } from '@local/Utils/Helpers'
import { FormikErrors, FormikValues, FormikTouched } from 'formik'

export const generateErrorMessage = ({
  errorMsg,
  touched,
}: {
  /** The error provided from Formik */
  errorMsg: string | FormikErrors<FormikValues>
  /** The touched object provided by Formik */
  touched: boolean | FormikTouched<FormikValues>
  // eslint-disable-next-line @typescript-eslint/no-base-to-string
}): string => (touched && errorMsg?.toString()) || ''

export const isEditingOrUpdating = (): boolean =>
  hasUrlParam(WizardQueryParams.Edit) || hasUrlParam(WizardQueryParams.Update)
