import React from 'react'
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Typography,
} from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { AdsClickRounded } from '@mui/icons-material'

export const ExperienceStepCard: React.FC<CardProps> = (props) => {
  const { title, body } = usePickEpiContent().wizardExperience.card

  return (
    <Card
      sx={{ bgcolor: (t) => t.palette.surface?.blue, border: 'none' }}
      {...props}
    >
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: 'h5' }}
        avatar={
          <Avatar sx={{ bgcolor: (t) => t.palette.info.light }}>
            <AdsClickRounded color="info" />
          </Avatar>
        }
      />
      <CardContent>
        <Typography variant="body1">{body}</Typography>
      </CardContent>
    </Card>
  )
}
