import React from 'react'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { TitleAndBody } from '@local/Components/TitleAndBody/TitleAndBody'
import { Content } from '@local/Components/Content/Content'
import ArticleImageCard from '@local/Components/ArticleImageCard/ArticleImageCard'
import useGtmProfilePageTracker from '@local/Utils/Hooks/useGtmPageTracker'
import { useFeatureFlag } from '@local/Utils/Hooks'

import { CommunicationsBoxSimple } from '../ProfilePage/Components/CommunicationsBoxSimple/CommunicationsBoxSimple'

import { WizardIntro } from './Components/WizardIntro'

export const TutorialPage: React.FC = () => {
  const { headingTitle, subtitle } =
    usePickEpiContent().wizardIntro.titleIngress
  const { PhotoTextBlock } = usePickEpiContent()

  const { isCommunicationBoxEnabled } = useFeatureFlag()

  useGtmProfilePageTracker(false)

  return (
    <Content>
      <TitleAndBody title={headingTitle} body={subtitle} mb={5} />
      {isCommunicationBoxEnabled && <CommunicationsBoxSimple mb={3} />}
      <WizardIntro sx={{ mb: 5 }} />

      <ArticleImageCard
        image={PhotoTextBlock.image}
        title={PhotoTextBlock.heading}
        description={PhotoTextBlock.body}
        linkText={PhotoTextBlock.button.text}
        link={PhotoTextBlock.button.url}
      />
    </Content>
  )
}
