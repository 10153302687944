import React from 'react'
import { useLazyGetCvQuery } from '@local/Features/WizardSteps/ExperienceStep'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { useGetWizardStep } from '@local/Utils/Hooks/wizard'
import { WizardStepPaths } from '@local/Router/routes'
import { GUID } from '@local/Types'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
} from '@mui/material'
import { DownloadForOffline, OpenInNew } from '@mui/icons-material'
import { useAuthentication } from '@trr/app-shell-data'
import { useGetProfileQuery } from '@local/Api/profileApi'
import { useGetSocialMediaQuery } from '@local/Api/socialMediaApi'

import ProfileSection from '../ProfileSection/ProfileSection'

const CustomTableCell = styled(TableCell)({
  paddingLeft: 0,
  paddingRight: 0,
  verticalAlign: 'top',
})

const LabelCell = ({ label, value }: { label: string; value: string }) => (
  <TableCell
    sx={{
      paddingLeft: 0,
      paddingRight: 0,
      whiteSpace: { xs: 'unset', sm: 'nowrap' },
      wordBreak: 'break-word',
      overflow: 'hidden',
      maxWidth: '300px',
      textOverflow: 'ellipsis',
    }}
  >
    <Box
      component="span"
      sx={{
        width: 200,
        display: { xs: 'block', sm: 'inline-block' },
      }}
    >
      {label}
    </Box>
    <span>{value}</span>
  </TableCell>
)

const ResumeBlock: React.FC = () => {
  const { title, linkLabel, chipLinkedin, chipCv } =
    usePickEpiContent().profile.experiences
  const { getWizardUrlStep } = useGetWizardStep()
  const [getCv] = useLazyGetCvQuery()
  const { sub } = useAuthentication()
  const { data: profile } = useGetProfileQuery(sub)
  const { data: socialMedia } = useGetSocialMediaQuery(sub, {
    refetchOnMountOrArgChange: true,
  })

  const handleDownloadCv = (id: GUID, name: string) => {
    void getCv({ cv: { pdfId: id, name: name }, clientId: sub })
  }

  const linkedInData = socialMedia?.find((link) => link?.type === 'linkedin')
  const cvData = profile?.documents[0]
  const hasResume = Boolean(cvData || linkedInData)

  return (
    <ProfileSection
      title={title}
      changeButtonProps={
        hasResume
          ? {
              title: linkLabel,
              href: getWizardUrlStep(WizardStepPaths.Yrkererfarenhet, true),
            }
          : null
      }
      data-testid="document-block"
    >
      <Table>
        <TableBody>
          <TableRow>
            <LabelCell
              label={chipLinkedin}
              value={linkedInData?.url ?? 'Länk till LinkedIn saknas'}
            />
            <CustomTableCell align="right">
              {linkedInData ? (
                <Button
                  variant="text"
                  size="small"
                  endIcon={<OpenInNew />}
                  target="_blank"
                  href={linkedInData.url}
                  data-trackable="false" // prevent sending personal information to GA
                  data-testid="resumeblock-linkedin-link"
                >
                  Visa
                </Button>
              ) : (
                <Button
                  variant="text"
                  size="small"
                  data-gtm-label="Lägg till LinkedIn"
                  data-testid="resumeblock-add-linkedin-link"
                  href={getWizardUrlStep(WizardStepPaths.Yrkererfarenhet, true)}
                >
                  Lägg till
                </Button>
              )}
            </CustomTableCell>
          </TableRow>
          <TableRow>
            <LabelCell label={chipCv} value={cvData?.name ?? 'CV saknas'} />
            <CustomTableCell align="right">
              {cvData ? (
                <Button
                  sx={{ whiteSpace: 'nowrap' }}
                  variant="text"
                  size="small"
                  data-gtm-label="Ladda ner CV"
                  onClick={() => handleDownloadCv(cvData.id, cvData.name)}
                  endIcon={<DownloadForOffline />}
                >
                  Ladda ner
                </Button>
              ) : (
                <Button
                  sx={{ whiteSpace: 'nowrap' }}
                  variant="text"
                  size="small"
                  data-gtm-label="Lägg till CV"
                  data-testid="resumeblock-add-cv-link"
                  href={getWizardUrlStep(WizardStepPaths.Yrkererfarenhet, true)}
                >
                  Lägg till
                </Button>
              )}
            </CustomTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </ProfileSection>
  )
}

export default ResumeBlock
