import React from 'react'
import { useGetProfileQuery } from '@local/Api/profileApi'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { CircularProgress } from '@mui/material'
import { useAuthentication } from '@trr/app-shell-data'

import { JobTypeFormik } from './Components/JobTypeForm/JobTypeForm'

export const JobTypeStep: React.FC = () => {
  const { sub } = useAuthentication()
  const { data, isLoading } = useGetProfileQuery(sub)
  const content = usePickEpiContent().wizardJobType

  return isLoading ? (
    <CircularProgress />
  ) : (
    <JobTypeFormik
      profileJobTypes={data?.additionalInformation}
      content={content}
    />
  )
}

export * from './Types/content'
