import React from 'react'
import { FromProfileProvider } from '@local/Utils/Hooks/useFromProfile'
import { TitleAndBody } from '@local/Components/TitleAndBody/TitleAndBody'
import { ProfilePuffs } from '@local/Features/MatchPuffs'
import ArticleImageCard from '@local/Components/ArticleImageCard/ArticleImageCard'
import { Content } from '@local/Components/Content/Content'
import { Button, Divider, Stack } from '@mui/material'
import { PatchOps } from '@local/Types/patchTypes'
import { useFeatureFlag, usePickEpiContent } from '@local/Utils/Hooks'
import { useAuthentication } from '@trr/app-shell-data'
import { usePatchProfileMutation } from '@local/Api/patchProfileApi'
import useGtmProfilePageTracker from '@local/Utils/Hooks/useGtmPageTracker'
import CompetenceBlock from '@local/Features/ProfilePage/Components/CompetenceBlock/CompetenceBlock'
import { SuggestionsBlock } from '@local/Features/ProfilePage/Components/SuggestionsBlock/SuggestionsBlock'
import { useGetProfileSuggestionsQuery } from '@local/Api/profileApi'

import JobTitlesBlock from '../JobTitlesBlock/JobTitlesBlock'
import { CommunicationsBoxSimple } from '../CommunicationsBoxSimple/CommunicationsBoxSimple'
import LocationsBlock from '../LocationsBlock/LocationsBlock'
import TypeOfEmploymentBlock from '../TypeOfEmploymentBlock/TypeOfEmploymentBlock'
import ResumeBlock from '../ResumeBlock/ResumeBlock'
import ContactInformationBlock from '../ContactInformationBlock/ContactInformationBlock'
import { ConsentBlock } from '../ConsentBlock/ConsentBlock'

export const ProfileContent = () => {
  const { title, ingress } = usePickEpiContent().profile.puffTitleIngress
  const { PhotoTextBlock } = usePickEpiContent()
  const {
    isCleanProfileButtonEnabled,
    isCommunicationBoxEnabled,
    isSuggestionsBlockEnabled,
  } = useFeatureFlag()
  const { sub } = useAuthentication()
  const [patchProfile] = usePatchProfileMutation()
  const { data: suggestions } = useGetProfileSuggestionsQuery(sub)
  const [hasClosedSuccessDialog, setHasClosedSuccessDialog] =
    React.useState(false)
  const handleCloseSuccessDialog = () => {
    setHasClosedSuccessDialog(true)
  }

  useGtmProfilePageTracker(true)

  const handleDeleteEverything = () => {
    void patchProfile({
      body: [
        {
          op: PatchOps.Replace,
          path: 'competences',
          value: [],
        },
        {
          op: PatchOps.Replace,
          path: 'jobTitles',
          value: [],
        },
        {
          op: PatchOps.Replace,
          path: 'locationinformations',
          value: [],
        },
        {
          op: PatchOps.Replace,
          path: 'additionalInformation/formsOfEmployment',
          value: [],
        },
        {
          op: PatchOps.Replace,
          path: 'additionalInformation/extentsOfEmployment',
          value: [],
        },
        {
          op: PatchOps.Replace,
          path: 'additionalInformation/otherPreferences',
          value: [],
        },
        {
          op: PatchOps.Replace,
          path: 'documents',
          value: [],
        },
        {
          op: PatchOps.Replace,
          path: 'completedWizard',
          value: false,
        },
      ],
      clientId: sub,
    })
  }

  return (
    <FromProfileProvider value>
      <Content>
        <Stack spacing={4}>
          <TitleAndBody
            title={title}
            body={ingress}
            data-testid="profile-title"
          />
          {isCommunicationBoxEnabled && <CommunicationsBoxSimple />}

          {isSuggestionsBlockEnabled &&
          !!suggestions?.totalCount &&
          !hasClosedSuccessDialog ? (
            <SuggestionsBlock
              suggestions={suggestions}
              handleCloseSuccessDialog={handleCloseSuccessDialog}
            />
          ) : (
            <ProfilePuffs />
          )}
          <Stack
            spacing={3}
            id="profile-section"
            data-testid="profile-section"
            divider={<Divider />}
          >
            <CompetenceBlock />
            <JobTitlesBlock />
            <LocationsBlock />
            <TypeOfEmploymentBlock />
            <Stack spacing={3}>
              <ResumeBlock />
              <ContactInformationBlock />
              <ConsentBlock />
            </Stack>
            {isCleanProfileButtonEnabled && (
              <Button
                onClick={handleDeleteEverything}
                sx={{ mt: 3, bgcolor: 'red' }}
              >
                Ta bort ALLT
              </Button>
            )}
          </Stack>
          <ArticleImageCard
            image={PhotoTextBlock.image}
            title={PhotoTextBlock.heading}
            description={PhotoTextBlock.body}
            linkText={PhotoTextBlock.button.text}
            link={PhotoTextBlock.button.url}
          />
        </Stack>
      </Content>
    </FromProfileProvider>
  )
}
