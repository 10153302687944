import React, { useId } from 'react'
import { ProfileChip } from '@local/Types'
import { Chip, Stack, Typography } from '@mui/material'
import { ChipStack } from '@local/Components/ChipStack/ChipStack'

interface ProfileChipBlockProps {
  chips: ProfileChip[]
  title?: string
}

const ProfileChipBlock: React.FC<ProfileChipBlockProps> = ({
  chips,
  title,
}) => {
  const labelId = useId()

  if (!chips || chips.length === 0) return null

  return (
    <Stack spacing={1}>
      {title && (
        <Typography
          id={labelId}
          variant="body1"
          color="text.secondary"
          gutterBottom
        >
          {title}
        </Typography>
      )}
      <ChipStack aria-labelledby={labelId}>
        {chips.map((chip) => (
          <Chip
            label={chip.name}
            key={`${chip.id}-${chip.name}`}
            icon={chip.icon}
          />
        ))}
      </ChipStack>
    </Stack>
  )
}

export default ProfileChipBlock
