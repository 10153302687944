import React, { useId } from 'react'
import { usePatchProfileMutation } from '@local/Api/patchProfileApi'
import { MatchingProfileSuggestions } from '@local/Api/profileApi'
import { ChipStack } from '@local/Components/ChipStack/ChipStack'
import { Target } from '@local/Components/Icons/Target'
import { SelectableChip } from '@local/Components/SelectableChip/SelectableChip'
import { JobTitle, LocationInformation } from '@local/Types'
import { PatchOps } from '@local/Types/patchTypes'
import { replaceEpiVariables } from '@local/Utils/Helpers'
import { locationTypeConverter } from '@local/Utils/Helpers/locationToChip'
import { usePickEpiContent } from '@local/Utils/Hooks'
import {
  Alert,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material'
import { useAuthentication, useDevice } from '@trr/app-shell-data'

interface SuggestionsBlockProps {
  suggestions: MatchingProfileSuggestions
  handleCloseSuccessDialog: () => void
}

export const SuggestionsBlock: React.FC<SuggestionsBlockProps> = ({
  suggestions,
  handleCloseSuccessDialog,
}) => {
  const suggestionsLabelId = useId()
  const content = usePickEpiContent().profile.suggestionsBlock

  const [patchProfile, { isSuccess }] = usePatchProfileMutation()
  const { sub } = useAuthentication()

  const { isMobile } = useDevice()

  const [selectedSuggestions, setSelectedSuggestions] = React.useState<
    typeof suggestions
  >({
    competences: [],
    locations: [],
    professions: [],
    totalCount: 0,
  })

  const selectedSuggestionsLength =
    selectedSuggestions.competences.length +
    selectedSuggestions.locations.length +
    selectedSuggestions.professions.length

  const handleCompetenceClick = (competence: string) => {
    setSelectedSuggestions((prev) => ({
      ...prev,
      competences: prev.competences.includes(competence)
        ? prev.competences.filter((c) => c !== competence)
        : [...prev.competences, competence],
    }))
  }

  const handleLocationClick = (location: LocationInformation) => {
    setSelectedSuggestions((prev) => ({
      ...prev,
      locations: prev.locations.some((l) => l.id === location.id)
        ? prev.locations.filter((l) => l.id !== location.id)
        : [...prev.locations, location],
    }))
  }

  const handleProfessionClick = (profession: JobTitle) => {
    setSelectedSuggestions((prev) => ({
      ...prev,
      professions: prev.professions.includes(profession)
        ? prev.professions.filter((p) => p !== profession)
        : [...prev.professions, profession],
    }))
  }

  const handleSubmit = () => {
    void patchProfile({
      body: [
        ...selectedSuggestions.competences.map((competence) => ({
          op: PatchOps.Add as const,
          path: 'competences/-' as const,
          value: competence,
        })),
        ...selectedSuggestions.locations.map((location) => ({
          op: PatchOps.Add as const,
          path: 'locationinformations/-' as const,
          value: location,
        })),
        ...selectedSuggestions.professions.map((profession) => ({
          op: PatchOps.Add as const,
          path: 'jobTitles/-' as const,
          value: profession,
        })),
      ],
      clientId: sub,
    })
  }

  if (isSuccess) {
    return (
      <Alert severity="info" onClose={handleCloseSuccessDialog}>
        {content.successMessage}
      </Alert>
    )
  }

  return (
    <Card>
      <CardHeader
        title={
          suggestions?.totalCount === 1
            ? content.headerSingle
            : replaceEpiVariables(content.header, {
                count: suggestions?.totalCount.toString(),
              })
        }
        avatar={
          isMobile ? undefined : (
            <Avatar sx={{ bgcolor: (t) => t.palette.surface?.purple }}>
              <Target color="primary" />
            </Avatar>
          )
        }
        titleTypographyProps={{ variant: 'h5' }}
        subheader={content.subheader}
        sx={{ alignItems: 'flex-start', mb: 2 }}
      />
      <CardContent sx={{ pl: isMobile ? undefined : 10 }}>
        <Stack spacing={2}>
          <Typography
            variant="body2"
            color="text.primary"
            id={suggestionsLabelId}
          >
            {content.suggestionsHeader}
          </Typography>
          <ChipStack aria-labelledby={suggestionsLabelId}>
            {suggestions?.competences?.map((competence) => (
              <SelectableChip
                key={competence}
                label={competence}
                onClick={() => handleCompetenceClick(competence)}
                isSelected={selectedSuggestions.competences.some(
                  (suggestion) => suggestion === competence
                )}
              />
            ))}
            {suggestions?.locations?.map((location) => (
              <SelectableChip
                key={location.id}
                label={`${location.name}, ${locationTypeConverter(location.type)}`}
                onClick={() => handleLocationClick(location)}
                isSelected={selectedSuggestions.locations.some(
                  (suggestion) => suggestion.id === location.id
                )}
              />
            ))}

            {suggestions?.professions?.map((profession) => (
              <SelectableChip
                key={profession.id}
                label={profession.name}
                onClick={() => handleProfessionClick(profession)}
                isSelected={selectedSuggestions.professions.some(
                  (suggestion) => suggestion.id === profession.id
                )}
              />
            ))}
          </ChipStack>
        </Stack>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end', pr: 3, pb: 3 }}>
        <Button
          size="small"
          color="primary"
          onClick={handleSubmit}
          disabled={!selectedSuggestionsLength}
        >
          {content.submitLabel}
        </Button>
      </CardActions>
    </Card>
  )
}
