import { Avatar, Typography } from '@mui/material'
import React from 'react'

interface AvatarWithTextProps {
  title: string
  avatarNumber: number
}

export const AvatarWithText = ({
  title,
  avatarNumber,
}: AvatarWithTextProps) => (
  <Typography
    variant="subtitle1"
    sx={{ display: 'flex', gap: 2, alignItems: 'center', pt: 1, pb: 1 }}
    gutterBottom
  >
    <Avatar
      sx={{
        bgcolor: (theme) => theme.palette.surface.green,
        color: (theme) => theme.palette.success.dark,
      }}
    >
      {avatarNumber}
    </Avatar>
    {title}
  </Typography>
)
