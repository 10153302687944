import { GUID } from '@local/Types/common'

export interface CvPdf {
  id: GUID
  name: string
}

type PatchDocumentType = 'Cv'

export interface PatchCvPdf extends CvPdf {
  type: PatchDocumentType
}

export interface JobTitle {
  id?: GUID
  name: string
  nameWithHighLightedSearchTerm?: string
}

export interface Address {
  city: string
}

export type SocialMediaTypes = 'linkedin' | 'other'

export interface SocialMedia {
  type: SocialMediaTypes
  url: string
}

export interface PesonalInformation {
  email: string
  mobilePhone: string
  firstName: string
  lastName: string
  address: Address
  socialMedia: SocialMedia[]
}

export interface Advisor {
  id: GUID
  firstName: string
  lastName: string
}

export interface AdditionalInformation {
  formsOfEmployment: FormsOfEmployment[]
  extentsOfEmployment: ExtentsOfEmployment[]
  otherPreferences: string[]
}

export interface LocationInformation {
  id: GUID
  name: string
  type: LocationInformationTypes
  locatedIn?: LocationInformation
}

export enum LocationInformationTypes {
  City = 'city',
  Municipality = 'municipality',
  County = 'county',
  Country = 'country',
}

export enum OptionalWizardSteps {
  Competences = 'Competences',
  Experience = 'Experience',
}

export enum ObligatoryWizardSteps {
  JobTitles = 'Jobtitles',
  Locations = 'Locations',
  Jobtypes = 'Jobtypes',
}

export interface Profile {
  id: GUID
  documents: PatchCvPdf[]
  completedWizard: boolean
  competences: string[]
  jobTitles: JobTitle[]
  locations: LocationInformation[]
  active: boolean
  isValid: boolean
  consent: boolean
  isSavedAfterMigration: boolean
  isAutoMigrated: boolean
  nonActiveReasons: string[]
  personalInformation: PesonalInformation
  advisor: Advisor
  additionalInformation: AdditionalInformation
  locationInformations: LocationInformation[]
  nonCompletedWizardSteps: ObligatoryWizardSteps[]
}

export enum ExtentsOfEmployment {
  PartTime = 'PartTime',
  FullTime = 'FullTime',
}

export enum FormsOfEmployment {
  Employee = 'Employee',
  Consultant = 'Consultant',
  Freelance = 'Freelance',
  TemporaryEmployee = 'TemporaryEmployee',
}

export enum OtherPreferences {
  RemoteWork = 'RemoteWork',
  Hybrid = 'Hybrid',
  OnSite = 'OnSite',
}
