export const UUIDRegex =
  /[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/i

export const linkedInRegEx =
  /^(http(s)?:\/\/)?(www\.)?linkedin\.com\/(pub|in|profile)/

export const urlRegEx = /^(http(s)?:\/\/)/

export const matchStringByRegex = (input: string, matcher: RegExp): string =>
  matcher.exec(input)?.toString()?.split(',')[0] || ''
