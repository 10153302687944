import React from 'react'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import {
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardProps,
} from '@mui/material'
import { WizardStepGuide } from '@trr/wizard-library'
import { CheckRounded } from '@mui/icons-material'
import { useCompletedSteps } from '@local/Features/TutorialPage/Hooks/useCompletedSteps'
import { ObligatoryWizardSteps, OptionalWizardSteps } from '@local/Types'
import {
  AvatarColor,
  WizardStepGuideItemProps,
} from '@trr/wizard-library/lib/@types/WizardStepGuide/WizardStepGuide'

export const WizardIntro = ({ ...props }: CardProps) => {
  const { titleIngress, steps, guideButton } = usePickEpiContent().wizardIntro
  const {
    completedSteps,
    hasCompletedWizard,
    hasStartedWizard,
    getNextStepUrl,
    sortByStepOrder,
  } = useCompletedSteps()

  const { titleText, ingressText, buttonText } = (() => {
    if (hasCompletedWizard) {
      return {
        titleText: titleIngress.titleOldProfile,
        ingressText: titleIngress.ingressOldProfile,
        buttonText: guideButton.oldProfile,
      }
    }

    if (hasStartedWizard) {
      return {
        titleText: titleIngress.titleContinue,
        ingressText: titleIngress.ingressContinue,
        buttonText: guideButton.continue,
      }
    }

    return {
      titleText: titleIngress.title,
      ingressText: titleIngress.ingress,
      buttonText: guideButton.create,
    }
  })()

  const wizardSteps: (WizardStepGuideItemProps & {
    step: ObligatoryWizardSteps | OptionalWizardSteps
  })[] = [
    {
      text: steps.competences,
      step: OptionalWizardSteps.Competences,
    },
    {
      text: steps.jobtitles,
      step: ObligatoryWizardSteps.JobTitles,
    },
    {
      text: steps.locations,
      step: ObligatoryWizardSteps.Locations,
    },
    {
      text: steps.typeOfEmployment,
      step: ObligatoryWizardSteps.Jobtypes,
    },
    {
      text: steps.experience,
      step: OptionalWizardSteps.Experience,
    },
  ].sort((a, b) => sortByStepOrder(a.step, b.step))

  return (
    <Card data-testid="wizard-intro-container" {...props}>
      <CardContent sx={{ padding: 3 }}>
        <Typography variant="h5" mb={2}>
          {titleText}
        </Typography>
        <Typography variant="body1" mb={1}>
          {ingressText}
        </Typography>
        <WizardStepGuide
          guideItems={wizardSteps.map((step, i) => ({
            text: step.text,
            color: 'green' as AvatarColor,
            icon: completedSteps.includes(step.step) ? (
              <CheckRounded titleAccess={`Steg ${i + 1} färdigt`} />
            ) : undefined,
          }))}
        />
      </CardContent>

      <CardActions sx={{ padding: '0 1.5rem 2rem' }}>
        <Button
          color="primary"
          title={buttonText}
          href={`${guideButton.url}${getNextStepUrl()}`}
          data-testid="create-profile-button"
        >
          {buttonText}
        </Button>
      </CardActions>
    </Card>
  )
}
