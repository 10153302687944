import { object, array, string, AnySchema } from 'yup'

import { CompetenceStepFormValues } from '../Types/formTypes'
import { CompetenceStepContent } from '../Types/content'

export const competenceStepValidationSchema = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  content: CompetenceStepContent
): AnySchema<CompetenceStepFormValues> =>
  object().shape({
    competences: array().of(string()),
  })
