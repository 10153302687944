import React from 'react'
import {
  BaseTextFieldProps,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { urlRegEx } from '@local/Utils/Helpers/regexes'
import { OpenInNew } from '@mui/icons-material'

interface LinkTextFieldProps extends BaseTextFieldProps {
  valueRegex: RegExp
  hasErrors?: boolean
  linkTestId?: string
  buttonAriaLabel?: string
  disableTracking?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const LinkTextField = ({
  hasErrors,
  valueRegex,
  linkTestId,
  value,
  defaultValue,
  buttonAriaLabel,
  disableTracking = false,
  onChange,
  ...props
}: LinkTextFieldProps) => {
  value = value !== undefined ? value : defaultValue
  const hasInput = () => value !== '' && value !== undefined
  const convertToHttp = (url: string) =>
    (value as string)?.match(urlRegEx) ? url : `https://${url}`

  return (
    <TextField
      onChange={onChange}
      defaultValue={value}
      InputProps={{
        endAdornment: !hasErrors &&
          hasInput() &&
          (value as string)?.match(valueRegex) && (
            <InputAdornment position="end">
              <Link
                to={{ pathname: convertToHttp(value as string) }}
                target="_blank"
                data-testid={linkTestId}
                data-trackable={disableTracking ? 'false' : 'true'}
              >
                <IconButton aria-label={buttonAriaLabel} edge="end">
                  <OpenInNew />
                </IconButton>
              </Link>
            </InputAdornment>
          ),
      }}
      {...props}
    />
  )
}
