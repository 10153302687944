import React from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
} from '@mui/material'
import { SelectableChip } from '@local/Components/SelectableChip/SelectableChip'
import { ChipStack } from '@local/Components/ChipStack/ChipStack'
import { CloseRounded } from '@mui/icons-material'
import { useDevice } from '@trr/app-shell-data'

interface SuggestionBoxProps<T> {
  title: string
  suggestions: T[]
  selected: T[]
  onClick: (suggestion: T) => void
  renderLabel: (suggestion: T) => string
  renderId?: (suggestion: T) => string | T
}

export const SuggestionBox = <T,>({
  title,
  suggestions,
  selected,
  onClick,
  renderLabel,
  renderId = (s) => s,
}: SuggestionBoxProps<T>) => {
  const { isMobile } = useDevice()

  const maxSuggestions = isMobile ? 5 : 10

  const [showAll, setShowAll] = React.useState(
    suggestions.length < maxSuggestions + 2
  )
  const [isOpen, setIsOpen] = React.useState(true)

  const handleShowAll = () => {
    setShowAll(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const visibleSuggestions = showAll
    ? suggestions
    : suggestions?.slice(0, maxSuggestions)

  return (
    <Collapse in={isOpen}>
      <Card>
        <CardHeader
          title={title}
          titleTypographyProps={{ variant: 'body1' }}
          action={
            <IconButton onClick={handleClose}>
              <CloseRounded />
            </IconButton>
          }
        />

        <CardContent>
          <ChipStack>
            {visibleSuggestions?.map((suggestion, i) => {
              const id = renderId(suggestion)
              const key = typeof id === 'string' ? id : i
              return (
                <SelectableChip
                  key={key}
                  onClick={() => onClick(suggestion)}
                  label={renderLabel(suggestion)}
                  isSelected={selected.map(renderId).includes(id)}
                />
              )
            })}
          </ChipStack>
        </CardContent>
        <CardActions>
          {!showAll && (
            <Button variant="text" onClick={handleShowAll} size="small">
              Visa fler
            </Button>
          )}
        </CardActions>
      </Card>
    </Collapse>
  )
}
