import { ThemeOptions } from '@mui/material'

export const localThemeOptions: ThemeOptions = {
  components: {
    MuiFormControl: {
      defaultProps: {
        focused: false,
      },
    },
  },
}
