import { useIsFeatureEnabled } from '@trr/app-shell-data'

export const useFeatureFlag = () => {
  const testFlagIsEnabled = useIsFeatureEnabled('testFlag')

  const isGtmTrackingEnabled = useIsFeatureEnabled(
    'gtm.enabled.matching-profil-v2'
  )
  const isCleanProfileButtonEnabled = useIsFeatureEnabled(
    'Matchning-Profil-V2-Frontend_cleanProfile_perm_240821'
  )
  const isCommunicationBoxEnabled = useIsFeatureEnabled(
    'Matchning-Profil-V2-Frontend_communicationsBox_temp_240821'
  )

  const isSuggestionsBlockEnabled = useIsFeatureEnabled(
    'Matchning-Profil-V2-Frontend_suggestionsBlock_temp_240828'
  )

  const isCustomJobTitlesEnabled = useIsFeatureEnabled(
    'Matchning-Profil-V2-Frontend_customJobTitle_temp_240923'
  )

  return {
    testFlagIsEnabled,
    isGtmTrackingEnabled,
    isCleanProfileButtonEnabled,
    isCommunicationBoxEnabled,
    isSuggestionsBlockEnabled,
    isCustomJobTitlesEnabled,
  }
}

export default useFeatureFlag
