import React, { useRef, useState } from 'react'
import { replaceEpiVariables } from '@local/Utils/Helpers'
import { useFormikContext } from 'formik'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Typography,
} from '@mui/material'
import { useAuthentication } from '@trr/app-shell-data'
import { useClickGtmTracker } from '@local/Utils/Hooks/gtm'

import { usePostCvMutation } from '../../Api/cvApi'
import { ExperienceStepFormValues } from '../../Types/experienceStepFormTypes'

import { CvList } from './CvRadioList'

interface CvModalProps {
  toggleModal: () => void
}

export const CvModal: React.FC<CvModalProps> = ({ toggleModal }) => {
  const [isPickingCv, setIsPickingCv] = useState(false)
  const { setFieldValue } = useFormikContext<ExperienceStepFormValues>()
  const [errorMessages, setErrorMessage] = useState([])
  const fileInputField = useRef<HTMLInputElement>(null)
  const [uploadCv] = usePostCvMutation()
  const { trackButtonClick } = useClickGtmTracker()
  const { sub } = useAuthentication()

  const {
    modalTitle,
    modalText,
    modalButtonFromTool,
    modalButtonUpload,
    modalFromToolTitle,
    errorFileSize,
    errorFileExtension,
  } = usePickEpiContent().wizardExperience.cv

  const handleOpenCvPicker = () => {
    setIsPickingCv(true)
    trackButtonClick('Ladda upp CV från verktyg')
  }

  const handleOpenFilePicker = () => {
    fileInputField.current.click()
    trackButtonClick('Ladda upp CV från fil')
  }

  const handleFileUpload = (files: FileList, clientId: string) => {
    const formData = new FormData()
    formData.append('file', files[0])
    formData.append('pdfType', '0')
    formData.append('userId', clientId)

    void uploadCv(formData)
      .unwrap()
      .then((uploadedCv) => {
        void setFieldValue('cvPdf', {
          name: uploadedCv.fileName,
          id: uploadedCv.pdfId,
        }).then(() => {
          toggleModal()
        })
      })
  }

  const validateUpload = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    const fileSizeLimitInBytes = 25600 * 1024
    const allowedFileExtentions = [
      { filetype: 'application/pdf', displayname: 'pdf' },
    ]
    if (file) {
      const errorMessages: string[] = []
      if (event.target.files[0].size > fileSizeLimitInBytes) {
        errorMessages.push(
          `${replaceEpiVariables(errorFileSize, {
            filesize: Math.floor(fileSizeLimitInBytes / 1024 / 1024).toString(),
          })}`
        )
      }
      if (
        !allowedFileExtentions
          .map((extension) => extension.filetype)
          .includes(file.type)
      ) {
        errorMessages.push(
          replaceEpiVariables(errorFileExtension, {
            filetypes: allowedFileExtentions
              .map((extension) => extension.displayname)
              .join(','),
          })
        )
      }
      if (errorMessages.length > 0) {
        setErrorMessage(errorMessages)
      } else {
        handleFileUpload(event.target.files, sub)
      }
    }
  }

  return (
    <Dialog onClose={toggleModal} open fullWidth maxWidth="sm">
      {!isPickingCv && (
        <>
          <DialogTitle sx={{ p: 3 }} data-testid="dialog--heading">
            {isPickingCv ? modalFromToolTitle : modalTitle}
          </DialogTitle>
          <DialogContent sx={{ pb: 0 }}>
            <Typography variant="body1">{modalText}</Typography>
          </DialogContent>
          <DialogActions sx={{ p: 3, gap: 2 }}>
            <Button
              onClick={handleOpenCvPicker}
              data-testid="upload-cvtool"
              variant="outlined"
              size="small"
            >
              {modalButtonFromTool}
            </Button>
            <Button
              onClick={handleOpenFilePicker}
              variant="contained"
              size="small"
            >
              {modalButtonUpload}
            </Button>
            <input
              type="file"
              accept=".pdf"
              hidden
              onChange={validateUpload()}
              ref={fileInputField}
            />
          </DialogActions>
          {errorMessages.length > 0 && (
            <DialogContent sx={{ pt: 0, alignSelf: 'end' }}>
              {errorMessages.map((errorMessage, i) => (
                <FormHelperText key={i} error>
                  {errorMessage}
                </FormHelperText>
              ))}
            </DialogContent>
          )}
        </>
      )}
      {isPickingCv && <CvList toggleModal={toggleModal} />}
    </Dialog>
  )
}
