import { useEffect } from 'react'
import useFeatureFlag from '@local/Utils/Hooks/useFeatureFlag'
import { useTrackVirtualView } from '@trr/gtm-tracking'

const useGtmProfilePageTracker = (complete: boolean) => {
  const stepTrigger = useTrackVirtualView('Profilsidan')
  const { isGtmTrackingEnabled } = useFeatureFlag()

  useEffect(() => {
    if (isGtmTrackingEnabled) {
      stepTrigger(complete ? 'Fullständig profil' : 'Ej skapad profil')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useGtmProfilePageTracker
