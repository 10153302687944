import React from 'react'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { ProfilePuff } from '@local/Features/MatchPuffs/Components/ProfilePuffs/ProfilePuff'
import { Target } from '@local/Components/Icons/Target'
import { ProfilePuffSkeleton } from '@local/Features/MatchPuffs/Components/ProfilePuffs/ProfilePuffSkeleton'

import { useGetMatchedJobsQuery } from '../../Api/matchedJobsApi'

export const JobMatchPuff: React.FC = () => {
  const {
    titleSingular,
    title,
    body,
    bodyAlternative,
    linkUrl,
    linkUrlParams,
  } = usePickEpiContent().profile.jobPuff
  const { data, isLoading } = useGetMatchedJobsQuery()

  if (isLoading) return <ProfilePuffSkeleton />

  const puffTitle =
    `${data?.totalHits ?? 0} ${data?.totalHits === 1 ? titleSingular : title}`.toLocaleLowerCase()

  return (
    <ProfilePuff
      Icon={Target}
      title={puffTitle}
      description={data?.totalHits > 0 ? body : bodyAlternative}
      to={data?.totalHits > 0 ? `${linkUrl}${linkUrlParams}` : linkUrl}
      testSelector="matched-jobs-link"
    />
  )
}
