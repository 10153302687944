import React, { useState } from 'react'
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  TextField,
} from '@mui/material'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { debounce } from 'lodash'
import { generateErrorMessage } from '@local/Utils/Helpers/form.helpers'
import { SearchRounded } from '@mui/icons-material'
import { CustomAutoComplete } from '@local/Components/CustomAutocomplete/CustomAutocomplete'
import { useCompetenceForm } from '@local/Features/WizardSteps/CompetenceStep/Hooks/useCompetenceForm'
import { useLazyGetCompetencesQuery } from '@local/Features/WizardSteps/CompetenceStep/Api/competenceApi'
import {
  capitalizeFirstLetter,
  boldify,
} from '@local/Utils/Helpers/stringHelpers'
import parse from 'html-react-parser'

interface Competence {
  title: string
  value: string
}

export const SearchCompetence: React.FC = () => {
  const { placeholder } = usePickEpiContent().wizardCompetence.searchCompetence

  const [getCompetences, { data: fetchedCompetences, isFetching, isLoading }] =
    useLazyGetCompetencesQuery()

  const {
    toggleCompetence,
    clearCompetences,
    setErrors,
    formikCompetences,
    errors,
    touched,
  } = useCompetenceForm()

  const [input, setInput] = useState('')

  const handleOnChange = (
    _event: React.SyntheticEvent<Element, Event>,
    _value: Competence[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<Competence | string>
  ) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      const addedCompetence = capitalizeFirstLetter(
        typeof details?.option === 'string'
          ? details.option
          : details?.option?.value
      )
      void toggleCompetence(addedCompetence)
    } else if (reason === 'clear') {
      clearCompetences()
    }
  }

  const handleInputChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setErrors({ competences: [] })
    fetchDebounce(value)
    setInput(value)
  }

  const fetchDebounce = debounce((searchTerm: string) => {
    if (!searchTerm) return
    void getCompetences(searchTerm)
  }, 300)

  const filteredOptions = fetchedCompetences
    ?.filter((option) => !formikCompetences.includes(option))
    .map(
      (option): Competence => ({
        title: option,
        value: option,
      })
    )

  const addedCompetences = formikCompetences.map(
    (competence): Competence => ({
      title: competence,
      value: competence,
    })
  )

  return (
    <CustomAutoComplete
      value={addedCompetences}
      multiple
      loading={isLoading || isFetching}
      options={!isFetching ? filteredOptions ?? [] : []}
      filterOptions={(options: Competence[], { inputValue }) =>
        inputValue.trim() !== ''
          ? options.push({
              title: `Lägg till: "${inputValue}"`,
              value: inputValue,
            }) && options
          : options
      }
      renderOption={(props, competence: Competence, { inputValue }) => (
        <li {...props} key={props.id} translate="no">
          {parse(boldify(competence?.title, inputValue))}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          name="competences"
          error={errors.competences?.length > 0}
          helperText={generateErrorMessage({
            touched: touched.competences !== undefined,
            errorMsg:
              typeof errors?.competences === 'string'
                ? errors.competences
                : undefined,
          })}
          FormHelperTextProps={{ id: 'competence-input-error' }}
          {...params}
          placeholder={placeholder}
        />
      )}
      getOptionLabel={(option: Competence) => option?.title}
      freeSolo
      inputValue={input}
      onChange={handleOnChange}
      onInputChange={handleInputChange}
      open={input !== ''}
      popupIcon={<SearchRounded />}
    />
  )
}
