import { baseApi } from '@local/Api/baseApi'

import { GetFilteredActivitiesResponse } from '../Types/activities.types'

export const activitiesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMatchingActivities: builder.query<GetFilteredActivitiesResponse, void>({
      query: () => ({
        url: '/aktivitetsbokningar/activities',
        params: {
          areaOfInterest: 'Träffa arbetsgivare',
          pageSize: 1,
          view: 'Anstalld',
        },
      }),
      providesTags: ['Profile'],
    }),
  }),
})

export const { useGetMatchingActivitiesQuery } = activitiesApi
