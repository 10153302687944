import React from 'react'
import { ProfilePuff } from '@local/Features/MatchPuffs/Components/ProfilePuffs/ProfilePuff'
import { Skeleton } from '@mui/material'

const SkeletonIcon = () => (
  <Skeleton variant="circular" width={24} height={24} />
)

export const ProfilePuffSkeleton: React.FC = () => (
  <ProfilePuff
    title={<Skeleton variant="text" width={'50%'} />}
    description={<Skeleton variant="text" width={'75%'} />}
    Icon={SkeletonIcon}
  />
)
