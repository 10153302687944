import React, { useId } from 'react'
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Stack,
  Typography,
} from '@mui/material'

interface ProfileSectionProps extends BoxProps {
  title: string
  changeButtonProps?: ButtonProps & { 'data-testid'?: string }
}

const ProfileSection: React.FC<ProfileSectionProps> = ({
  title,
  children,
  changeButtonProps,
  ...props
}) => {
  const labelId = useId()

  return (
    <Box {...props}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography id={labelId} variant="h5" gutterBottom>
          {title}
        </Typography>
        {changeButtonProps && (
          <Button
            variant="text"
            size="small"
            aria-label="Ändra"
            {...changeButtonProps}
          >
            Ändra
          </Button>
        )}
      </Stack>
      <Stack spacing={2} aria-labelledby={labelId}>
        {children}
      </Stack>
    </Box>
  )
}

export default ProfileSection
