import { Box, Typography } from '@mui/material'
import React from 'react'

interface WizardStepHeaderProps {
  title: string
  subtitle?: string
}

export const WizardStepHeader: React.FC<WizardStepHeaderProps> = ({
  title,
  subtitle,
}) => (
  <Box>
    <Typography variant="h5">{title}</Typography>
    {subtitle && (
      <Typography variant="subtitle2" color="textSecondary">
        {subtitle}
      </Typography>
    )}
  </Box>
)
