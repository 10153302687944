import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import React from 'react'
import { Link } from '@mui/material'

const ScrollToProfile = () => {
  const { title } = usePickEpiContent().profile.scrollToProfileLink

  return (
    <Link component="a" href="#profile-section" underline="hover">
      {title}
    </Link>
  )
}

export default ScrollToProfile
