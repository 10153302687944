import React from 'react'
import { Card, CardContent, CardHeader } from '@mui/material'
import { SelectableChip } from '@local/Components/SelectableChip/SelectableChip'
import { ChipStack } from '@local/Components/ChipStack/ChipStack'

interface SuggestionBoxProps<T> {
  title: string
  suggestions: T[]
  selected: T[]
  onClick: (suggestion: T) => void
  renderLabel: (suggestion: T) => string
  renderId?: (suggestion: T) => string | T
}

export const SuggestionBox = <T,>({
  title,
  suggestions,
  selected,
  onClick,
  renderLabel,
  renderId = (s) => s,
}: SuggestionBoxProps<T>) => (
  <Card>
    <CardHeader title={title} titleTypographyProps={{ variant: 'body1' }} />
    <CardContent>
      <ChipStack>
        {suggestions?.map((suggestion, i) => {
          const id = renderId(suggestion)
          const key = typeof id === 'string' ? id : i
          return (
            <SelectableChip
              key={key}
              onClick={() => onClick(suggestion)}
              label={renderLabel(suggestion)}
              isSelected={selected.map(renderId).includes(id)}
            />
          )
        })}
      </ChipStack>
    </CardContent>
  </Card>
)
