import useFeatureFlag from '@local/Utils/Hooks/useFeatureFlag'
import { useTrackCustomClick } from '@trr/gtm-tracking'

interface ButtonOptions {
  label?: string
  href?: string
}

export const useClickGtmTracker = () => {
  const { isGtmTrackingEnabled } = useFeatureFlag()
  const trackClick = useTrackCustomClick()

  const trackButtonClick = (identifier: string, options?: ButtonOptions) => {
    if (isGtmTrackingEnabled) {
      trackClick(identifier, options)
    }
  }

  return {
    trackButtonClick,
  }
}
