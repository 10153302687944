import React from 'react'
import { Box, BoxProps, Typography } from '@mui/material'

interface TitleAndBodyProps extends BoxProps {
  title: string
  body?: string
}

export const TitleAndBody = ({ title, body, ...props }: TitleAndBodyProps) => (
  <Box {...props}>
    <Typography variant="h2" gutterBottom>
      {title}
    </Typography>
    {body && <Typography variant="body1">{body}</Typography>}
  </Box>
)
