import React from 'react'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { Button, CardActions, CardContent, Typography } from '@mui/material'
import { AvatarWithText } from '@local/Components/AvatarWithText/AvatarWithText'
import { CustomCard } from '@local/Components/CustomCard/CustomCard'
import { sanitizeTrailingSlash } from '@local/Utils/Helpers/sanitization'

import { useGetMatchingActivitiesQuery } from '../../Api/activitiesApi'

export const ActivityPuff: React.FC = () => {
  const {
    title,
    titleSingular,
    bodyAlternative,
    body,
    linkText,
    linkTextAlternative,
    linkUrl,
    linkUrlParams,
  } = usePickEpiContent().profile.activityPuff

  const { data, isLoading } = useGetMatchingActivitiesQuery()
  const formattedLinkUrl = `${sanitizeTrailingSlash(linkUrl)}${data?.totalCount > 0 ? linkUrlParams : null}`

  return (
    <CustomCard variant="outlined">
      <CardContent>
        {!isLoading && (
          <>
            <AvatarWithText
              avatarNumber={data?.totalCount ?? 0}
              title={data?.totalCount === 1 ? titleSingular : title}
            />
            <Typography variant="body1">
              {data?.totalCount > 0 ? body : bodyAlternative}
            </Typography>
          </>
        )}
      </CardContent>
      <CardActions>
        {!!linkText && !!linkUrl && (
          <Button
            variant="text"
            size="small"
            href={formattedLinkUrl}
            data-testid="matched-activities-link"
          >
            {data?.totalCount > 0 ? linkText : linkTextAlternative}
          </Button>
        )}
      </CardActions>
    </CustomCard>
  )
}
