import React from 'react'
import { useFormikContext } from 'formik'
import { Checkbox, FormControlLabel } from '@mui/material'
import { useLazyGetLocationsQuery } from '@local/Features/WizardSteps/JobLocationStep/Api/locationsApi'
import {
  JobLocationFormTypes,
  JobLocationStepFormValues,
} from '@local/Features/WizardSteps/JobLocationStep/Types/formTypes'

interface LocationCheckboxProps {
  checked: boolean
  label: string
  searchTerm: string
}

export const LocationCheckbox: React.FC<LocationCheckboxProps> = ({
  checked,
  label,
  searchTerm,
}) => {
  const [getLocations] = useLazyGetLocationsQuery()
  const { values, setFieldValue } =
    useFormikContext<JobLocationStepFormValues>()

  const toggleLocation = () => {
    if (
      !values.foreignLocations.find(
        (location) => location.name.trim() === searchTerm
      )
    ) {
      void getLocations(searchTerm).then((result) => {
        void setFieldValue(JobLocationFormTypes.ForeignLocations, [
          result.data[0],
          ...values.foreignLocations,
        ])
      })
    } else {
      void setFieldValue(
        JobLocationFormTypes.ForeignLocations,
        values.foreignLocations.filter(
          (location) => location.name.trim() !== searchTerm
        )
      )
    }
  }

  return (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          checked={checked}
          onChange={toggleLocation}
          data-testid="location-checkbox"
        />
      }
    />
  )
}
