import React from 'react'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { sanitizeTrailingSlash } from '@local/Utils/Helpers/sanitization'
import { ProfilePuff } from '@local/Features/MatchPuffs/Components/ProfilePuffs/ProfilePuff'
import { QuestionAnswerRounded } from '@mui/icons-material'
import { ProfilePuffSkeleton } from '@local/Features/MatchPuffs/Components/ProfilePuffs/ProfilePuffSkeleton'

import { useGetMatchingActivitiesQuery } from '../../Api/activitiesApi'

export const ActivityPuff: React.FC = () => {
  const {
    title,
    titleSingular,
    bodyAlternative,
    body,
    linkUrl,
    linkUrlParams,
  } = usePickEpiContent().profile.activityPuff

  const { data, isLoading } = useGetMatchingActivitiesQuery()
  const formattedLinkUrl = `${sanitizeTrailingSlash(linkUrl)}${data?.totalCount > 0 ? linkUrlParams : null}`

  if (isLoading) return <ProfilePuffSkeleton />

  const puffTitle = `${data?.totalCount ?? 0} ${
    data?.totalCount === 1 ? titleSingular : title
  }`.toLocaleLowerCase()

  return (
    <ProfilePuff
      Icon={QuestionAnswerRounded}
      title={puffTitle}
      description={data?.totalCount > 0 ? body : bodyAlternative}
      to={data?.totalCount > 0 ? formattedLinkUrl : linkUrl}
    />
  )
}
