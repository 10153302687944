import React, { useState } from 'react'
import { debounce } from 'lodash'
import { LocationInformation } from '@local/Types'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { generateErrorMessage } from '@local/Utils/Helpers/form.helpers'
import { CustomAutoComplete } from '@local/Components/CustomAutocomplete/CustomAutocomplete'
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Stack,
  TextField,
} from '@mui/material'
import { SearchRounded } from '@mui/icons-material'
import parse from 'html-react-parser'
import { useLocationForm } from '@local/Features/WizardSteps/JobLocationStep/Hooks/useLocationForm'

import {
  GetLocationsResponse,
  useLazyGetLocationsQuery,
} from '../../Api/locationsApi'
import {
  filterAlreadyPickedLocations,
  filterCountries,
} from '../../Utils/formHelper'

interface SearchLocationProps {
  label: string
}

export const SearchLocations = ({ label }: SearchLocationProps) => {
  const [getLocations, { data: locationInformations, isFetching, isLoading }] =
    useLazyGetLocationsQuery()

  const {
    locations,
    addLocation,
    removeLocation,
    clearLocations,
    touched,
    errors,
    setErrors,
  } = useLocationForm()

  const [input, setInput] = useState('')

  const { locationPicker } = usePickEpiContent().wizardVarOchHur

  const handleOnChange = (
    _event: React.SyntheticEvent<Element, Event>,
    _value: LocationInformation[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<LocationInformation>
  ) => {
    if (reason === 'removeOption') {
      removeLocation(details.option)
    } else if (reason === 'selectOption') {
      addLocation(details.option)
    } else if (reason === 'clear') {
      clearLocations()
    }
  }

  const handleInputChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setErrors({ locations: [] })
    fetchDebounce(value)
    setInput(value)
  }

  const fetchDebounce = debounce((searchTerm: string) => {
    if (!searchTerm) return
    void getLocations(searchTerm)
  }, 300)

  const filteredLocations: LocationInformation[] = filterAlreadyPickedLocations(
    filterCountries(locationInformations),
    locations
  )

  const isOptionEqualToValue = (
    option: LocationInformation,
    value: LocationInformation
  ) => option.id === value.id

  return (
    <Stack spacing={1}>
      <CustomAutoComplete
        multiple
        value={locations}
        options={!isFetching ? (filteredLocations ?? []) : []}
        loading={isLoading || isFetching}
        getOptionLabel={(location: GetLocationsResponse) =>
          `${location.name}, ${locationPicker[location.type]}`
        }
        renderOption={(props, location: GetLocationsResponse) => (
          <li {...props} key={location.id} translate="no">
            {parse(location.nameWithHighLightedSearchTerm)}
            &nbsp;{locationPicker[location.type]}
          </li>
        )}
        inputValue={input}
        onChange={handleOnChange}
        onInputChange={handleInputChange}
        isOptionEqualToValue={isOptionEqualToValue}
        open={input !== ''}
        popupIcon={<SearchRounded />}
        renderInput={(params) => (
          <TextField
            name="locations"
            error={
              errors.locations?.length > 0 && touched.locations !== undefined
            }
            helperText={generateErrorMessage({
              touched: touched.locations !== undefined,
              errorMsg: errors.locations as string,
            })}
            FormHelperTextProps={{ id: 'locations-input-error' }}
            {...params}
            placeholder={label}
          />
        )}
      />
    </Stack>
  )
}
