import React from 'react'
import {
  JobTypeFormTypes,
  JobTypeStepFormValues,
} from '@local/Features/WizardSteps/JobTypeStep/Types/formTypes'
import { FormsOfEmployment } from '@local/Types'
import { generateErrorMessage } from '@local/Utils/Helpers/form.helpers'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Typography,
} from '@mui/material'
import { useFormikContext } from 'formik'
import { usePickEpiContent } from '@local/Utils/Hooks'

export const FormsOfEmploymentForm: React.FC = () => {
  const { values, setFieldValue, touched, errors } =
    useFormikContext<JobTypeStepFormValues>()

  const { jobType } = usePickEpiContent().wizardJobType

  const handleChange =
    (newForm: FormsOfEmployment) => (_: unknown, checked: boolean) => {
      if (checked) {
        void setFieldValue(JobTypeFormTypes.FormsOfEmployment, [
          newForm,
          ...values.formsOfEmployment,
        ])
      } else {
        void setFieldValue(
          JobTypeFormTypes.FormsOfEmployment,
          values.formsOfEmployment.filter((form) => form !== newForm)
        )
      }
    }

  const checkboxes = [
    {
      label: jobType.tillsvidareOption,
      type: FormsOfEmployment.Employee,
      testId: 'employee-checkbox',
    },
    {
      label: jobType.tidsbegransadOption,
      type: FormsOfEmployment.TemporaryEmployee,
      testId: 'temporary-employee-checkbox',
    },
    {
      label: jobType.konsultaSomAnstalldOption,
      type: FormsOfEmployment.Consultant,
      testId: 'consultant-checkbox',
    },
    {
      label: jobType.konsultaSomForetagOption,
      type: FormsOfEmployment.Freelance,
      testId: 'freelance-checkbox',
    },
  ]

  return (
    <FormControl>
      <Typography variant="h6" color="textPrimary" component={FormLabel}>
        {jobType.heading}
      </Typography>
      {checkboxes.map(({ label, type, testId }) => (
        <FormControlLabel
          key={type}
          label={label}
          onChange={handleChange(type)}
          checked={values.formsOfEmployment.includes(type)}
          control={<Checkbox />}
          data-testid={testId}
        />
      ))}
      <FormHelperText error data-testid="formsofemployment-error">
        {generateErrorMessage({
          touched: touched.formsOfEmployment !== undefined,
          errorMsg: errors.formsOfEmployment as string,
        })}
      </FormHelperText>
    </FormControl>
  )
}
