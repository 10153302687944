import {
  JobLocationFormTypes,
  JobLocationStepFormValues,
} from '@local/Features/WizardSteps/JobLocationStep/Types/formTypes'
import { LocationInformation } from '@local/Types'
import { useFormikContext } from 'formik'

export const useLocationForm = () => {
  const {
    values: { locations },
    setFieldValue,
    ...formikContext
  } = useFormikContext<JobLocationStepFormValues>()

  const addLocation = (location: LocationInformation) => {
    void setFieldValue(
      JobLocationFormTypes.Locations,
      [...locations, location],
      false
    )
  }

  const removeLocation = (location: LocationInformation) => {
    const newLocations = locations.filter((l) => l.id !== location.id)
    void setFieldValue(JobLocationFormTypes.Locations, newLocations, false)
  }

  const clearLocations = () => {
    void setFieldValue(JobLocationFormTypes.Locations, [], false)
  }

  const toggleLocation = (location: LocationInformation) =>
    locations.some((l) => l.id === location.id)
      ? removeLocation(location)
      : addLocation(location)

  return {
    locations,
    addLocation,
    removeLocation,
    clearLocations,
    toggleLocation,
    ...formikContext,
  }
}
