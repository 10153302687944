import React from 'react'
import { CvPdf } from '@local/Types'
import { ListItem, ListItemButton, Typography } from '@mui/material'

interface CvRadioItemProps {
  cv: CvPdf
  onChange: (cv: CvPdf) => void
}

export const CvListItem: React.FC<CvRadioItemProps> = ({ cv, onChange }) => {
  const handleOnChange = () => {
    onChange(cv)
  }

  return (
    <ListItem>
      <ListItemButton onClick={handleOnChange} data-testid="choose-cv-button">
        <Typography component="span" variant="body2" noWrap>
          {cv.name}
        </Typography>
      </ListItemButton>
    </ListItem>
  )
}
