import HTMLMapper from '@local/Components/HTMLMapper/HTMLMapper'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { Close } from '@mui/icons-material'
import {
  Alert,
  Box,
  BoxProps,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

export const CommunicationsBoxSimple = ({ ...props }: BoxProps) => {
  const { title, text } = usePickEpiContent().communicationsBox
  const [open, setOpen] = useState(true)

  return (
    <Box {...props}>
      <Collapse in={open}>
        <Alert
          severity={title.severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false)
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          <Typography variant="subtitle1" gutterBottom>
            {title.title}
          </Typography>
          {text?.mainBody && <HTMLMapper body={text.mainBody} />}
        </Alert>
      </Collapse>
    </Box>
  )
}
