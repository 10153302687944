import {
  AdditionalInformation,
  LocationInformation,
  OtherPreferences,
} from '@local/Types'

import { JobLocationStepContent } from './content'

export interface JobLocationStepFormValues {
  locations: LocationInformation[]
  foreignLocations: LocationInformation[]
  otherPreferences: OtherPreferences[]
}

export interface JobLocationStepFormProps {
  content: JobLocationStepContent
  varOchHur: JobLocationApiModel
}

export interface JobLocationApiModel {
  additionalInformation: AdditionalInformation
  locationInformations: LocationInformation[]
}

export enum JobLocationFormTypes {
  OtherPreferences = 'otherPreferences',
  ForeignLocations = 'foreignLocations',
  Locations = 'locations',
}
