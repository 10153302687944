import { Routes, WizardStepPaths } from '@local/Router/routes'
import { useHistory } from 'react-router-dom'
import { History } from 'history'
import {
  useClickGtmTracker,
  useNavigationGtmTracker,
} from '@local/Utils/Hooks/gtm'

export const useWizardNavigation = () => {
  const history = useHistory() as History
  const stepList = Object.values(WizardStepPaths)
  const { trackValidFormSubmit, trackGoBack, trackCancel, trackSave } =
    useNavigationGtmTracker()
  const { trackButtonClick } = useClickGtmTracker()

  const goToNextWizardStep = () => {
    const currentUrlWithStep = history.location.pathname.split('/')
    const currentStep = currentUrlWithStep.pop() || currentUrlWithStep.pop()
    const nextStep =
      stepList[stepList.indexOf(currentStep as WizardStepPaths) + 1]
    const goToUrl = `${currentUrlWithStep.join('/')}/${nextStep}`

    trackValidFormSubmit(goToUrl)
    history.push(`${currentUrlWithStep.join('/')}/${nextStep}`)
  }

  const goToPreviousWizardStep = () => {
    const currentUrlWithStep = history.location.pathname.split('/')
    const currentStep = currentUrlWithStep.pop() || currentUrlWithStep.pop()
    const nextStep =
      stepList[stepList.indexOf(currentStep as WizardStepPaths) - 1]
    const goToUrl = `${currentUrlWithStep.join('/')}/${nextStep}`

    trackGoBack(goToUrl)
    history.push(goToUrl)
  }

  const goToProfilePage = (label?: string) => {
    const goToUrl = `/${Routes.Start}`
    trackButtonClick('button_click', {
      label: label ?? 'Gå till profilsidan',
      href: goToUrl,
    })

    history.push(goToUrl)
  }

  const cancel = () => {
    trackCancel()
    history.go(-1)
  }

  const saveAndExit = () => {
    trackSave()
    history.go(-1)
  }

  return {
    goToNextWizardStep,
    goToPreviousWizardStep,
    goToProfilePage,
    saveAndExit,
    cancel,
  }
}
