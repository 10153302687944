import React from 'react'
import { useJobtitlesForm } from '@local/Features/WizardSteps/JobtitlesStep/Hooks/useJobTitlesForm'
import { useAuthentication } from '@trr/app-shell-data'
import { useGetProfileSuggestionsQuery } from '@local/Api/profileApi'
import { SuggestionBox } from '@local/Components/SuggestionBox/SuggestionBox'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { uniqueJobtitleId } from '@local/Features/WizardSteps/JobtitlesStep/Helpers/jobtitleHelpers'

export const JobtitlesSuggestions: React.FC = () => {
  const { sub } = useAuthentication()
  const { data: suggestions } = useGetProfileSuggestionsQuery(sub)
  const { toggleJobtitle, jobtitles } = useJobtitlesForm()

  const { title } = usePickEpiContent().wizardJobtitles.jobtitlesSuggestions

  if (!suggestions?.professions?.length) return null

  return (
    <SuggestionBox
      title={title}
      suggestions={suggestions?.professions}
      selected={jobtitles}
      onClick={toggleJobtitle}
      renderLabel={(suggestion) => suggestion.name}
      renderId={uniqueJobtitleId}
    />
  )
}
