import { useFormikContext } from 'formik'
import React, { useState } from 'react'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import {
  Button,
  FormHelperText,
  IconButton,
  Typography,
  styled,
} from '@mui/material'
import { Delete, FileUpload } from '@mui/icons-material'

import { ExperienceStepFormValues } from '../../Types/experienceStepFormTypes'

import { CvModal } from './CvModal'

const CvTextAndButton = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

export const CvBlock: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { title, button } = usePickEpiContent().wizardExperience.cv

  const { values, errors, touched, setFieldValue } =
    useFormikContext<ExperienceStepFormValues>()

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev)
  }

  const handleRemoveCv = () => {
    void setFieldValue('cvPdf', null)
  }

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {!values.cvPdf ? (
        <Button
          size="small"
          variant="outlined"
          onClick={toggleModal}
          data-trackable="false"
          data-testid="open-modal-button"
          endIcon={<FileUpload />}
        >
          <span>{button}</span>
        </Button>
      ) : (
        <CvTextAndButton data-testid="cv-filename-chip">
          <Typography variant="body1" mr={2}>
            {values.cvPdf.name}
          </Typography>
          <IconButton onClick={handleRemoveCv}>
            <Delete color="action" />
          </IconButton>
        </CvTextAndButton>
      )}

      {touched.cvPdf && errors?.cvPdf && (
        <FormHelperText error data-testid="cv-upload-error">
          {errors.cvPdf as string}
        </FormHelperText>
      )}

      {isModalOpen && <CvModal toggleModal={toggleModal} />}
    </div>
  )
}
