import { baseApi } from '@local/Api/baseApi'

export const consentApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    putConsent: builder.mutation<void, boolean>({
      query: (consent) => ({
        url: `/client-match/Profile/consent`,
        method: 'PUT',
        body: { consent },
      }),
      invalidatesTags: ['Profile'],
    }),
  }),
})

export const { usePutConsentMutation } = consentApi
