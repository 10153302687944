import { CompetenceStepFormValues } from '@local/Features/WizardSteps/CompetenceStep/Types/formTypes'
import { useFormikContext } from 'formik'

export const useCompetenceForm = () => {
  const {
    values: { competences: formikCompetences },
    setFieldValue,
    setErrors,
    touched,
    errors,
  } = useFormikContext<CompetenceStepFormValues>()

  const toggleCompetence = (competence: string) => {
    const isNewCompetence = !formikCompetences.includes(competence)
    isNewCompetence ? addCompetence(competence) : removeCompetence(competence)
  }

  const clearCompetences = () => {
    void setFieldValue('competences', [], false)
  }

  const addCompetence = (competence: string) => {
    void setFieldValue('competences', [...formikCompetences, competence], false)
  }

  const removeCompetence = (competence: string) => {
    const newCompetences = formikCompetences.filter((s) => s !== competence)
    void setFieldValue('competences', newCompetences, false)
  }

  return {
    formikCompetences,
    setErrors,
    touched,
    errors,
    toggleCompetence,
    addCompetence,
    removeCompetence,
    clearCompetences,
  }
}
