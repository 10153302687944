import { FormikHelpers as FormikActions, FormikValues } from 'formik'
import { trrDateUTC } from '@local/Utils/Helpers/formatDate'
import { matchStringByRegex, UUIDRegex } from '@local/Utils/Helpers/regexes'

export const setFieldValue =
  (
    _setFieldValue: FormikActions<FormikValues>['setFieldValue'],
    fieldName: string,
    _setFieldTouched?: FormikActions<FormikValues>['setFieldTouched']
  ) =>
  (value: unknown): void => {
    const wrappedValue =
      value instanceof Date ? trrDateUTC(value).valueOf() : value
    void _setFieldValue(fieldName, wrappedValue)
    if (_setFieldTouched) {
      void _setFieldTouched(fieldName, true, false)
    }
  }

export const getAnsokanId = () =>
  matchStringByRegex(window.location.pathname, UUIDRegex)

export const replaceEpiVariables = (
  text: string,
  variables: { [key: string]: string } = {}
) => {
  let result = text
  Object.keys(variables).forEach((key) => {
    const regex = new RegExp(`{{${key}}}`, 'g')
    result = result?.replace(regex, variables[key])
  })
  return result
}

export const isLinkAnchor = (href: string): boolean => href.startsWith('#')

export const isLinkExternal = (href: string): boolean =>
  href.substring(0, 4) === 'http' ||
  href.startsWith('mailto:') ||
  href.startsWith('tel:')

type MapToOutput<I, U = I> = (v: I) => U

export const formatCurrencySEK: MapToOutput<number, string> = (amount) =>
  new Intl.NumberFormat('sv-se', {
    style: 'currency',
    currency: 'SEK',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .format(amount)
    //eslint-disable-next-line
    .replace(/\,00([^\d])/g, '$1') // Edge bug where minimumFractionDigits is ignored

export const formatPercentageNumber = (value: number): string => {
  const formattedNumber = value / 100

  return new Intl.NumberFormat('sv-se', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 20,
  }).format(formattedNumber)
}

export const getImageQuery = (imageUrl: string, width: number): string => {
  const searchParams = new URLSearchParams({
    width: width.toString(),
    dpr: window.devicePixelRatio.toString(),
  })

  return `${imageUrl}?${searchParams.toString()}`
}

export const hasUrlParam = (name: string): boolean =>
  new URLSearchParams(window.location.search).has(name)

export const createImageAltTextFromFileName = (image: string): string => {
  const regexMatchSizeDescriptor = /\d{1,4}[xX]\d{1,4}/

  if (typeof image !== 'string') {
    return ''
  }

  return image
    .split('/')
    .pop()
    .split('.')
    .shift()
    .replaceAll('-', ' ')
    .replaceAll('_', ' ')
    .replace(regexMatchSizeDescriptor, '')
    .trim()
}
