import {
  ExtentsOfEmployment,
  FormsOfEmployment,
  LocationInformation,
  ProfileChip,
} from '@local/Types'

export enum EngLocationType {
  County = 'county',
  City = 'city',
  Municipality = 'municipality',
}

export enum SweLocationType {
  Lan = 'län',
  Tatort = 'tätort',
  Kommun = 'kommun',
}

export const locationTypeConverter = (type: string): string => {
  if (type) {
    switch (type.toLocaleLowerCase() as EngLocationType) {
      case EngLocationType.County:
        return SweLocationType.Lan
      case EngLocationType.City:
        return SweLocationType.Tatort
      case EngLocationType.Municipality:
        return SweLocationType.Kommun
      default:
        return type
    }
  } else {
    return ''
  }
}

export enum SweEmpoymentType {
  Anstalld = 'Tillsvidareanställning',
  Konsult = 'Konsulta som anställd',
  Frilans = 'Konsulta som egenföretagare',
  Tidbegransad = 'Tidsbegränsad anställning',
}

export const employmentTypeConverter = (type: FormsOfEmployment) => {
  switch (type) {
    case FormsOfEmployment.Employee:
      return SweEmpoymentType.Anstalld
    case FormsOfEmployment.Consultant:
      return SweEmpoymentType.Konsult
    case FormsOfEmployment.Freelance:
      return SweEmpoymentType.Frilans
    case FormsOfEmployment.TemporaryEmployee:
      return SweEmpoymentType.Tidbegransad
    default:
      return type
  }
}

export enum SweEmploymentExtent {
  Heltid = 'Heltid',
  Deltid = 'Deltid',
}

export const employmentExtentConverter = (type: ExtentsOfEmployment) => {
  switch (type) {
    case ExtentsOfEmployment.FullTime:
      return SweEmploymentExtent.Heltid
    case ExtentsOfEmployment.PartTime:
      return SweEmploymentExtent.Deltid
    default:
      return type
  }
}

export enum EngEmploymentLocation {
  RemoteWork = 'RemoteWork',
  OnSite = 'OnSite',
  Hybrid = 'Hybrid',
}

export enum SweEmploymentLocation {
  Distans = 'På distans',
  PaPlats = 'På plats',
  Hybrid = 'Hybrid, kombinera på plats och distans',
}
// TODO: Implement this function
export const employmentLocationConverter = (type: string) => {
  switch (type as EngEmploymentLocation) {
    case EngEmploymentLocation.RemoteWork:
      return SweEmploymentLocation.Distans
    case EngEmploymentLocation.OnSite:
      return SweEmploymentLocation.PaPlats
    case EngEmploymentLocation.Hybrid:
      return SweEmploymentLocation.Hybrid
    default:
      return type
  }
}

export const locationToChip = (location: LocationInformation): ProfileChip => ({
  id: location.id,
  name: `${location.name}, ${locationTypeConverter(location.type)}`,
})
