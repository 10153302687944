import React from 'react'
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Typography,
} from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { Target } from '@local/Components/Icons/Target'

export const ExperienceStepCard: React.FC<CardProps> = (props) => {
  const { title, body } = usePickEpiContent().wizardExperience.card

  return (
    <Card {...props}>
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: 'h5' }}
        avatar={
          <Avatar sx={{ bgcolor: (t) => t.palette.surface?.purple }}>
            <Target color="primary" />
          </Avatar>
        }
      />
      <CardContent>
        <Typography variant="body1"> {body}</Typography>
      </CardContent>
    </Card>
  )
}
