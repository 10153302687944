import {
  JobLocationFormTypes,
  JobLocationStepFormValues,
} from '@local/Features/WizardSteps/JobLocationStep/Types/formTypes'
import { OtherPreferences } from '@local/Types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Typography,
} from '@mui/material'
import { useFormikContext } from 'formik'
import React from 'react'

export const WorkplaceTypeForm: React.FC = () => {
  const { workplaceType } = usePickEpiContent().wizardVarOchHur

  const { values, setFieldValue } =
    useFormikContext<JobLocationStepFormValues>()

  const handleAddOtherPreferences = (option: OtherPreferences) => {
    void setFieldValue(JobLocationFormTypes.OtherPreferences, [
      option,
      ...values.otherPreferences,
    ])
  }

  const handleRemoveOtherPreferences = (option: OtherPreferences) => {
    void setFieldValue(
      JobLocationFormTypes.OtherPreferences,
      values.otherPreferences.filter(
        (existingOption) => existingOption !== option
      )
    )
  }

  const handleOtherPreferencesChange =
    (option: OtherPreferences) => (_: unknown, checked: boolean) => {
      checked
        ? handleAddOtherPreferences(option)
        : handleRemoveOtherPreferences(option)
    }

  const checkboxes = [
    { label: workplaceType.onsite, type: OtherPreferences.OnSite },
    { label: workplaceType.online, type: OtherPreferences.RemoteWork },
    { label: workplaceType.hybrid, type: OtherPreferences.Hybrid },
  ]

  return (
    <FormControl>
      <Typography variant="h6" color="textPrimary" component={FormLabel}>
        {workplaceType.heading}
      </Typography>
      {checkboxes.map(({ label, type }) => (
        <FormControlLabel
          key={type}
          label={label}
          onChange={handleOtherPreferencesChange(type)}
          checked={values.otherPreferences.includes(type)}
          control={<Checkbox />}
        />
      ))}
    </FormControl>
  )
}
