import React from 'react'
import { useGetProfileSuggestionsQuery } from '@local/Api/profileApi'
import { SuggestionBox } from '@local/Components/SuggestionBox/SuggestionBox'
import { useLocationForm } from '@local/Features/WizardSteps/JobLocationStep/Hooks/useLocationForm'
import { useAuthentication } from '@trr/app-shell-data'
import { usePickEpiContent } from '@local/Utils/Hooks'

export const LocationSuggestions: React.FC = () => {
  const { sub } = useAuthentication()
  const { locations, toggleLocation } = useLocationForm()

  const { locationPicker } = usePickEpiContent().wizardVarOchHur

  const { data } = useGetProfileSuggestionsQuery(sub)

  if (!data?.locations?.length) return null

  return (
    <SuggestionBox
      title={locationPicker.suggestionsTitle}
      suggestions={data?.locations}
      selected={locations}
      onClick={toggleLocation}
      renderLabel={(loc) => [loc.name, locationPicker[loc.type]].join(', ')}
      renderId={(loc) => loc.id}
    />
  )
}
