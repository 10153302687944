import { baseApi } from '@local/Api/baseApi'
import { removeDuplicates } from '@local/Utils/Helpers/arrayHelpers'
import { capitalizeFirstLetter } from '@local/Utils/Helpers/stringHelpers'

export interface GetCompetencesResponse {
  concept: string
  term: string
}

export const competenceApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getCompetences: builder.query<string[], string>({
      query: (search) => ({
        url: `/kompetens/prognosportal/competence/${search.toLowerCase()}`,
      }),
      transformResponse: (response: GetCompetencesResponse[]): string[] =>
        removeDuplicates(
          response,
          (a, b) => a?.concept.toLowerCase() === b?.concept.toLowerCase()
        )?.map((competence: GetCompetencesResponse) =>
          capitalizeFirstLetter(competence.concept)
        ),
    }),
  }),
})

export const { useLazyGetCompetencesQuery } = competenceApi
