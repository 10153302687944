import React from 'react'
import { useWizard } from '@trr/wizard-library'
import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { usePatchProfileMutation } from '@local/Api/patchProfileApi'
import { PatchOps } from '@local/Types/patchTypes'
import { useWizardNavigation } from '@local/Utils/Hooks/wizard'
import { isEmpty } from 'ramda'
import { ProfileWizardStep } from '@local/Components/ProfileWizardStep/ProfileWizardStep'
import { useUpdateSocialMediaMutation } from '@local/Api/socialMediaApi'
import { isEditingOrUpdating } from '@local/Utils/Helpers/form.helpers'
import { Stack } from '@mui/material'
import { useAuthentication } from '@trr/app-shell-data'
import {
  useNavigationGtmTracker,
  useClickGtmTracker,
} from '@local/Utils/Hooks/gtm'
import { usePutConsentMutation } from '@local/Api/consentApi'
import { ConsentBlock } from '@local/Features/WizardSteps/ExperienceStep/Components/ConsentBlock/ConsentBlock'
import { WizardStepHeader } from '@local/Components/WizardStepHeader/WizardStepHeader'

import { CvBlock } from '../CvBlock/CvBlock'
import { ExperienceStepFormValues } from '../../Types/experienceStepFormTypes'
import { LinkedInBlock } from '../LinkedInBlock/LinkedInBlock'
import { ExperienceStepCard } from '../ExperienceStepCard/ExperienceStepCard'

export const ExperienceStep: React.FC = () => {
  const { stepCount, activeStep } = useWizard()
  const { goToProfilePage, goToPreviousWizardStep, saveAndExit } =
    useWizardNavigation()

  const { stepGuide, text, buttons } = usePickEpiContent().wizardExperience
  const [patchProfile] = usePatchProfileMutation()
  const [updateSocialMedia] = useUpdateSocialMediaMutation()
  const [putConsent] = usePutConsentMutation()

  const { values, setTouched, validateForm } =
    useFormikContext<ExperienceStepFormValues>()
  const isEditing = isEditingOrUpdating()
  const { trackButtonClick } = useClickGtmTracker()
  const { trackInvalidFormSubmit } = useNavigationGtmTracker()
  const { sub } = useAuthentication()

  const handlePatchAndNext = async () => {
    await patchProfile({
      body: [
        {
          op: PatchOps.Replace,
          path: 'documents',
          value: values.cvPdf ? [{ ...values.cvPdf, type: 'Cv' }] : null,
        },
      ],
      clientId: sub,
    }).unwrap()
    await putConsent(values.consent).unwrap()
    await updateSocialMedia({
      url: values.linkedIn,
      type: 'linkedin',
      clientId: sub,
    }).unwrap()

    if (isEditing) {
      saveAndExit()
    } else {
      goToProfilePage('Skapa profil')
    }
  }

  const handleNext = () => {
    validateForm()
      .then((validationErrors) => {
        if (!isEmpty(validationErrors)) {
          void setTouched(
            setNestedObjectValues<FormikTouched<ExperienceStepFormValues>>(
              validationErrors,
              true
            )
          )
          trackInvalidFormSubmit()
        } else {
          trackButtonClick('Linkedinprofil', {
            label: values.linkedIn?.length > 0 ? 'Angiven' : 'Ej angiven',
          })
          void handlePatchAndNext()
        }
      })
      .catch((e) => console.error(e))
  }

  return (
    <ProfileWizardStep
      progressBar={{ max: stepCount, current: activeStep + 1 }}
      nextButton={{
        onClick: handleNext,
        text: isEditing ? buttons.save : buttons.create,
      }}
      prevButton={{ onClick: goToPreviousWizardStep }}
    >
      {!isEditing && <ExperienceStepCard />}
      <WizardStepHeader
        title={stepGuide.currentStepTitle}
        subtitle={text.ingress}
      />
      <Stack spacing={3}>
        <LinkedInBlock />
        <CvBlock />
      </Stack>
      {!isEditing && <ConsentBlock />}
    </ProfileWizardStep>
  )
}
