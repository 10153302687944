import React from 'react'
import { useGetProfileQuery } from '@local/Api/profileApi'
import { ScrollToProfile } from '@local/Features/ProfilePage'
import {
  CircularProgress,
  List,
  Stack,
  StackProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useAuthentication } from '@trr/app-shell-data'

import { ActivityPuff } from './ActivityPuff'
import { JobMatchPuff } from './JobMatchPuff'

export const ProfilePuffs = ({ ...props }: StackProps) => {
  const { sub } = useAuthentication()
  const { isLoading } = useGetProfileQuery(sub)

  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (isLoading) return <CircularProgress />

  return (
    <Stack spacing={1} {...props}>
      {isMobile && <ScrollToProfile />}
      <List>
        <JobMatchPuff />
        <ActivityPuff />
      </List>
    </Stack>
  )
}
