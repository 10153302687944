import React from 'react'
import { Provider } from 'react-redux'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import { AppShellRouter, KeyRoute } from '@local/Router'
import { store } from '@local/Store/configureStore'
import { PageKeys } from '@local/Router/routes'
import { ProfilePage } from '@local/Features/ProfilePage'
import { CreateProfileWizard } from '@local/Features/CreateProfileWizard'
import { ThemeProvider, createTheme } from '@mui/material'
import { defaultLocale, themeOptions as trrThemeOptions } from '@trr/mui-theme'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { sv } from 'date-fns/locale'
import { mergeContentWithFallback } from '@local/Utils/Content/mergeContentWithFallback'
import { GTMTracker } from '@trr/gtm-tracking'
import { deepmerge } from '@mui/utils'
import { localThemeOptions } from '@local/Utils/Theme/localThemeOptions'

const themeOptions = deepmerge(trrThemeOptions, localThemeOptions)

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => {
  const theme = createTheme(themeOptions, defaultLocale)
  const content = mergeContentWithFallback(appShellData.content)

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
        <GTMTracker mfName="matching-profil-v2">
          <Provider store={store}>
            <AppShellDataProvider value={{ ...appShellData, content }}>
              <AppShellRouter currentKey={appShellData.currentKey}>
                <KeyRoute urlKey={PageKeys.Start}>
                  <ProfilePage />
                </KeyRoute>
                <KeyRoute urlKey={PageKeys.CreateProfile}>
                  <CreateProfileWizard />
                </KeyRoute>
              </AppShellRouter>
            </AppShellDataProvider>
          </Provider>
        </GTMTracker>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
