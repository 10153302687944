import React, { useId } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { SearchLocations } from '@local/Features/WizardSteps/JobLocationStep/Components/SearchLocations/SearchLocations'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ForeignCountryForm } from '@local/Features/WizardSteps/JobLocationStep/Components/JobLocationStep/LocationsForm/ForeignCountryForm/ForeignCountryForm'

export const LocationsForm: React.FC = () => {
  const { locationPicker } = usePickEpiContent().wizardVarOchHur

  const labelId = useId()

  return (
    <Box>
      <Typography variant="h6" color="textPrimary" mb={2} id={labelId}>
        {locationPicker.heading}
      </Typography>
      <Stack spacing={3} aria-labelledby={labelId}>
        <SearchLocations />
        <ForeignCountryForm />
      </Stack>
    </Box>
  )
}
