import React from 'react'
import { CircularProgress } from '@mui/material'
import { useGetProfileQuery } from '@local/Api/profileApi'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { useAuthentication } from '@trr/app-shell-data'

import { JobLocationStepFormik } from './Components/JobLocationStepForm/JobLocationStepForm'

export const JobLocationStep: React.FC = () => {
  const { sub } = useAuthentication()
  const { data, isLoading } = useGetProfileQuery(sub)
  const content = usePickEpiContent().wizardVarOchHur

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <JobLocationStepFormik
      varOchHur={{
        additionalInformation: data?.additionalInformation,
        locationInformations: data?.locationInformations,
      }}
      content={content}
    />
  )
}

export * from './Types/content'
