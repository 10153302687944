import React from 'react'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Typography,
} from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ExtentsOfEmployment } from '@local/Types'
import { useFormikContext } from 'formik'
import {
  JobTypeFormTypes,
  JobTypeStepFormValues,
} from '@local/Features/WizardSteps/JobTypeStep/Types/formTypes'
import { generateErrorMessage } from '@local/Utils/Helpers/form.helpers'

export const ExtentsOfEmploymentForm: React.FC = () => {
  const { jobPartOrFullTime } = usePickEpiContent().wizardJobType

  const { values, setFieldValue, touched, errors } =
    useFormikContext<JobTypeStepFormValues>()

  const handleChange =
    (newExtent: ExtentsOfEmployment) => (_: unknown, checked: boolean) => {
      if (checked) {
        void setFieldValue(JobTypeFormTypes.ExtentsOfEmployment, [
          newExtent,
          ...values.extentsOfEmployment,
        ])
      } else {
        void setFieldValue(
          JobTypeFormTypes.ExtentsOfEmployment,
          values.extentsOfEmployment.filter((extent) => extent !== newExtent)
        )
      }
    }

  const checkboxes = [
    {
      label: jobPartOrFullTime.heltidOption,
      type: ExtentsOfEmployment.FullTime,
      testId: 'fulltime-checkbox',
    },
    {
      label: jobPartOrFullTime.deltidOption,
      type: ExtentsOfEmployment.PartTime,
      testId: 'parttime-checkbox',
    },
  ]

  return (
    <FormControl>
      <Typography variant="h6" color="textPrimary" component={FormLabel}>
        {jobPartOrFullTime.heading}
      </Typography>
      {checkboxes.map(({ label, type, testId }) => (
        <FormControlLabel
          key={type}
          label={label}
          onChange={handleChange(type)}
          checked={values.extentsOfEmployment.includes(type)}
          control={<Checkbox />}
          data-testId={testId}
        />
      ))}
      <FormHelperText error data-testid="extentsofemployment-error">
        {generateErrorMessage({
          touched: touched.extentsOfEmployment !== undefined,
          errorMsg: errors.extentsOfEmployment as string,
        })}
      </FormHelperText>
    </FormControl>
  )
}
