import {
  ExtentsOfEmployment,
  FormsOfEmployment,
  AdditionalInformation,
} from '@local/Types'

import { JobTypeStepContent } from './content'

export interface JobTypeStepFormValues {
  formsOfEmployment: FormsOfEmployment[]
  extentsOfEmployment: ExtentsOfEmployment[]
}

export enum JobTypeFormTypes {
  FormsOfEmployment = 'formsOfEmployment',
  ExtentsOfEmployment = 'extentsOfEmployment',
}

export interface JobTypeStepFormProps {
  content: JobTypeStepContent
  profileJobTypes: AdditionalInformation
}
