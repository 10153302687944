import { LocationInformation, LocationInformationTypes } from '@local/Types'

export const filterCountries = (
  locationInformations: LocationInformation[],
  reverseFilter = false
) =>
  locationInformations?.filter((l) =>
    !reverseFilter
      ? (l.type.toLowerCase() as LocationInformationTypes) !==
        LocationInformationTypes.Country
      : (l.type.toLowerCase() as LocationInformationTypes) ===
        LocationInformationTypes.Country
  )

export const filterAlreadyPickedLocations = (
  locationInformations: LocationInformation[],
  pickedLocations: LocationInformation[]
) =>
  locationInformations?.filter(
    (l) => !pickedLocations?.find((pl) => pl.id === l.id && pl.type === l.type)
  )
