import React, { useState } from 'react'
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  TextField,
  Typography,
} from '@mui/material'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { JobTitle } from '@local/Types'
import { debounce } from 'lodash'
import { generateErrorMessage } from '@local/Utils/Helpers/form.helpers'
import { SearchRounded } from '@mui/icons-material'
import { CustomAutoComplete } from '@local/Components/CustomAutocomplete/CustomAutocomplete'
import parse from 'html-react-parser'
import { useFeatureFlag } from '@local/Utils/Hooks'
import { replaceEpiVariables } from '@local/Utils/Helpers'
import { capitalizeFirstLetter } from '@local/Utils/Helpers/stringHelpers'
import { useJobtitlesForm } from '@local/Features/WizardSteps/JobtitlesStep/Hooks/useJobTitlesForm'
import {
  isEqualJobtitle,
  uniqueJobtitleId,
} from '@local/Features/WizardSteps/JobtitlesStep/Helpers/jobtitleHelpers'

import { useLazyGetJobtitlesQuery } from '../../Api/jobtitleApi'

export const SearchJobs: React.FC = () => {
  const { title, label, addCustomJobtitleFormat } =
    usePickEpiContent().wizardJobtitles.searchJobtitles

  const [getJobtitles, { data: jobtitleOptions, isFetching, isLoading }] =
    useLazyGetJobtitlesQuery()

  const {
    jobtitles,
    setErrors,
    touched,
    errors,
    removeJobtitle,
    addJobtitle,
    clearJobtitles,
  } = useJobtitlesForm()

  const { isCustomJobTitlesEnabled } = useFeatureFlag()

  const [input, setInput] = useState('')

  const trimmedInput = input.trim()

  const inputExistsInOptions = jobtitleOptions?.some(
    (jobtitle) =>
      jobtitle.name.toLocaleLowerCase() === trimmedInput.toLocaleLowerCase()
  )

  const jobtitleOptionsWithCurrentTypedValue: JobTitle[] = [
    ...(jobtitleOptions ?? []),
    ...(trimmedInput && isCustomJobTitlesEnabled && !inputExistsInOptions
      ? [
          {
            id: undefined,
            name: capitalizeFirstLetter(trimmedInput),
            nameWithHighLightedSearchTerm: replaceEpiVariables(
              addCustomJobtitleFormat,
              {
                value: capitalizeFirstLetter(trimmedInput),
              }
            ),
          },
        ]
      : []),
  ]

  const filteredJobtitles = jobtitleOptionsWithCurrentTypedValue?.filter(
    (option) => !jobtitles.some((j) => j.name === option.name)
  )

  const handeOnChange = (
    _event: React.SyntheticEvent<Element, Event>,
    _value: JobTitle[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<JobTitle>
  ) => {
    if (reason === 'selectOption') {
      addJobtitle(details.option)
    } else if (reason === 'removeOption') {
      removeJobtitle(details.option)
    } else if (reason === 'clear') {
      clearJobtitles()
    }
  }

  const handleInputChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setErrors({ jobtitles: [] })
    fetchDebounce(value)
    setInput(value)
  }

  const fetchDebounce = debounce((searchTerm: string) => {
    if (!searchTerm) return
    void getJobtitles(searchTerm)
  }, 300)

  return (
    <>
      <Typography variant="body1" fontWeight={'bold'} marginBottom={1.5}>
        {title}
      </Typography>
      <CustomAutoComplete
        multiple
        data-testid="jobtitles-autocomplete"
        options={!isFetching ? (filteredJobtitles ?? []) : []}
        loading={isLoading || isFetching}
        value={jobtitles}
        getOptionLabel={(jobtitle: JobTitle) => jobtitle.name}
        renderOption={(props, jobtitle: JobTitle) => (
          <li {...props} key={uniqueJobtitleId(jobtitle)} translate="no">
            {parse(jobtitle.nameWithHighLightedSearchTerm)}
          </li>
        )}
        inputValue={input}
        onChange={handeOnChange}
        onInputChange={handleInputChange}
        open={trimmedInput !== ''}
        popupIcon={<SearchRounded />}
        isOptionEqualToValue={isEqualJobtitle}
        renderInput={(params) => (
          <TextField
            name="jobtitle"
            error={errors.jobtitles?.length > 0}
            helperText={generateErrorMessage({
              touched: touched.jobtitles !== undefined,
              errorMsg:
                typeof errors?.jobtitles === 'string'
                  ? errors.jobtitles
                  : undefined,
            })}
            FormHelperTextProps={{ id: 'jobtitle-input-error' }}
            {...params}
            placeholder={label}
          />
        )}
      />
    </>
  )
}
