import React, { useEffect, useState } from 'react'
import { usePutConsentMutation } from '@local/Api/consentApi'
import { useGetProfileQuery } from '@local/Api/profileApi'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { useAuthentication } from '@trr/app-shell-data'
import { useClickGtmTracker } from '@local/Utils/Hooks/gtm'

import ProfileSection from '../ProfileSection/ProfileSection'
import { ConsentSwitch } from '../../../../Components/ConsentSwitch/ConsentSwitch'

export const ConsentBlock: React.FC = () => {
  const [hasConsent, setHasConsent] = useState(false)
  const { sub } = useAuthentication()
  const { data: profileData } = useGetProfileQuery(sub)
  const [setConsent] = usePutConsentMutation()
  const { trackButtonClick } = useClickGtmTracker()

  const { title, description, heading } = usePickEpiContent().profile.consent

  useEffect(() => {
    setHasConsent(profileData?.consent)
  }, [profileData?.consent])

  const HandleSetConsent = (_: React.ChangeEvent, checked: boolean) => {
    trackButtonClick('Delning av profil', { label: checked ? 'Ja' : 'Nej' })
    setHasConsent(checked)
    void setConsent(checked)
  }

  return (
    <ProfileSection title={heading} data-testid="consent-block">
      <ConsentSwitch
        label={title}
        description={description}
        checked={hasConsent}
        onChange={HandleSetConsent}
      />
    </ProfileSection>
  )
}
