import { baseApi } from '@local/Api/baseApi'

interface MatchedJobsResponse {
  totalHits: number
}

export const matchedJobsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMatchedJobs: builder.query<MatchedJobsResponse, void>({
      query: () => ({
        url: '/Jobs/search/matches/count',
      }),
      providesTags: ['Profile'],
    }),
  }),
})

export const { useGetMatchedJobsQuery } = matchedJobsApi
