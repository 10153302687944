import { useEffect } from 'react'
import { useWizard } from '@trr/wizard-library'
import { useTrackVirtualView } from '@trr/gtm-tracking'
import useFeatureFlag from '@local/Utils/Hooks/useFeatureFlag'

export const useWizardGtmTracker = (virtualSubviewName: string) => {
  const stepTrigger = useTrackVirtualView(virtualSubviewName)
  const { activeStep } = useWizard()
  const { isGtmTrackingEnabled } = useFeatureFlag()

  useEffect(() => {
    if (isGtmTrackingEnabled) {
      stepTrigger(`Step: ${activeStep}`)
    }
  }, [stepTrigger, activeStep, isGtmTrackingEnabled])
}
