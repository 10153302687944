import {
  Routes,
  WizardQueryParams,
  WizardStepPaths,
} from '@local/Router/routes'
import { useFromProfile } from '@local/Utils/Hooks/useFromProfile'

export const useGetWizardStep = () => {
  const fromProfile = useFromProfile()

  const getWizardUrlStep = (step: WizardStepPaths, edit = false): string => {
    const params = new URLSearchParams({})

    if (fromProfile) {
      params.append(WizardQueryParams.Update, 'true')
    } else if (edit) {
      params.append(WizardQueryParams.Edit, 'true')
    }

    const goToUrl = `/${Routes.Start}/${Routes.CreateProfile}/${step}?${params.toString()}`

    return goToUrl
  }

  return {
    getWizardUrlStep,
  }
}
