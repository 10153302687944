import React from 'react'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { Button, CardActions, CardContent, Typography } from '@mui/material'
import { AvatarWithText } from '@local/Components/AvatarWithText/AvatarWithText'
import { CustomCard } from '@local/Components/CustomCard/CustomCard'

import { useGetMatchedJobsQuery } from '../../Api/matchedJobsApi'

export const JobMatchPuff: React.FC = () => {
  const {
    titleSingular,
    title,
    body,
    bodyAlternative,
    linkUrl,
    linkText,
    linkTextAlternative,
    linkUrlParams,
  } = usePickEpiContent().profile.jobPuff
  const { data, isLoading } = useGetMatchedJobsQuery()

  return (
    <CustomCard variant="outlined">
      <CardContent>
        {!isLoading && (
          <>
            <AvatarWithText
              avatarNumber={data?.totalHits ?? 0}
              title={data?.totalHits === 1 ? titleSingular : title}
            />
            <Typography variant="body1">
              {data?.totalHits > 0 ? body : bodyAlternative}
            </Typography>
          </>
        )}
      </CardContent>
      <CardActions>
        <Button
          variant="text"
          size="small"
          href={data?.totalHits > 0 ? `${linkUrl}${linkUrlParams}` : linkUrl}
          data-testid="matched-jobs-link"
        >
          {data?.totalHits > 0 ? linkText : linkTextAlternative}
        </Button>
      </CardActions>
    </CustomCard>
  )
}
