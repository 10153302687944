import { useGetProfileQuery } from '@local/Api/profileApi'
import { WizardStepPaths } from '@local/Router/routes'
import { ObligatoryWizardSteps, OptionalWizardSteps } from '@local/Types'
import { useAuthentication } from '@trr/app-shell-data'
import { difference } from 'ramda'

export const useCompletedSteps = () => {
  const { sub } = useAuthentication()
  const { data: profile } = useGetProfileQuery(sub)

  const sortByStepOrder = (
    a: ObligatoryWizardSteps | OptionalWizardSteps,
    b: ObligatoryWizardSteps | OptionalWizardSteps
  ) => {
    const stepOrder = [
      OptionalWizardSteps.Competences,
      ObligatoryWizardSteps.JobTitles,
      ObligatoryWizardSteps.Locations,
      ObligatoryWizardSteps.Jobtypes,
      OptionalWizardSteps.Experience,
    ]
    return stepOrder.indexOf(a) - stepOrder.indexOf(b)
  }

  const completedObligatorySteps: ObligatoryWizardSteps[] = difference(
    Object.values(ObligatoryWizardSteps),
    profile.nonCompletedWizardSteps
  )

  const completedOptionalSteps: OptionalWizardSteps[] = [
    ...(profile?.competences?.length > 0 ||
    completedObligatorySteps.includes(ObligatoryWizardSteps.JobTitles)
      ? [OptionalWizardSteps.Competences]
      : []),
    ...(profile?.documents.length > 0 ? [OptionalWizardSteps.Experience] : []),
  ]

  const completedSteps: (ObligatoryWizardSteps | OptionalWizardSteps)[] = [
    ...completedObligatorySteps,
    ...completedOptionalSteps,
  ]

  const nonCompletedWizardSteps = difference(
    [
      ...Object.values(ObligatoryWizardSteps),
      ...Object.values(OptionalWizardSteps),
    ].sort(),
    completedSteps
  )

  const hasCompletedWizard = profile.nonCompletedWizardSteps.length === 0
  const hasStartedWizard = completedSteps.length > 0

  const getNextStepUrl = () => {
    const minStepNotFinished = nonCompletedWizardSteps.sort(sortByStepOrder)[0]

    switch (minStepNotFinished) {
      case OptionalWizardSteps.Competences:
        return WizardStepPaths.Kunskaper
      case ObligatoryWizardSteps.JobTitles:
        return WizardStepPaths.Jobtitlar
      case ObligatoryWizardSteps.Locations:
        return WizardStepPaths.VarOchHur
      case ObligatoryWizardSteps.Jobtypes:
        return WizardStepPaths.JobTypes
      case OptionalWizardSteps.Experience:
        return WizardStepPaths.Yrkererfarenhet
      default:
        return WizardStepPaths.Sammanfattning
    }
  }

  return {
    hasCompletedWizard,
    hasStartedWizard,
    completedSteps,
    getNextStepUrl,
    sortByStepOrder,
  }
}
