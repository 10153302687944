import React from 'react'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { useGetWizardStep } from '@local/Utils/Hooks/wizard'
import { WizardStepPaths } from '@local/Router/routes'
import { LocationInformationTypes } from '@local/Types'
import {
  employmentLocationConverter,
  locationToChip,
} from '@local/Utils/Helpers/locationToChip'
import { useAuthentication } from '@trr/app-shell-data'
import { useGetProfileQuery } from '@local/Api/profileApi'

import ProfileChipBlock from '../ProfileChipBlock/ProfileChipBlock'
import ProfileSection from '../ProfileSection/ProfileSection'

const LocationsBlock: React.FC = () => {
  const { getWizardUrlStep } = useGetWizardStep()
  const { sub } = useAuthentication()
  const { data: profile } = useGetProfileQuery(sub)

  const { title, linkLabel, chipCountry, chipLocation, chipWorkplaceType } =
    usePickEpiContent().profile.locations

  const employmentLocationList =
    profile?.additionalInformation.otherPreferences.map(
      (employmentLocation) => ({
        name: employmentLocationConverter(employmentLocation),
        id: employmentLocation,
      })
    )

  const locationsChipList = profile?.locationInformations
    .filter(
      (loc) =>
        (loc.type.toLowerCase() as LocationInformationTypes) !==
        LocationInformationTypes.Country
    )
    .map((location) => locationToChip(location))

  const countryChipList = profile?.locationInformations
    .filter(
      (loc) =>
        (loc.type.toLowerCase() as LocationInformationTypes) ===
        LocationInformationTypes.Country
    )
    .map((location) => ({
      id: location.id,
      name: location.name,
    }))

  return (
    <ProfileSection
      title={title}
      changeButtonProps={{
        href: getWizardUrlStep(WizardStepPaths.VarOchHur, true),
        title: linkLabel,
      }}
      data-testid="locations-block"
    >
      <ProfileChipBlock
        title={chipWorkplaceType}
        chips={employmentLocationList}
      />
      <ProfileChipBlock title={chipLocation} chips={locationsChipList} />
      <ProfileChipBlock title={chipCountry} chips={countryChipList} />
    </ProfileSection>
  )
}

export default LocationsBlock
