import { Profile, SocialMedia } from '@local/Types'

export const mapExperienceStepPropsToValues = (
  profile: Profile,
  socialMedia: SocialMedia[]
) => {
  const cvPdf = profile?.documents.find(
    (doc) => doc.type.toLocaleLowerCase() === 'cv'
  )

  return {
    linkedIn: socialMedia?.find(
      (sm) => sm.type.toLocaleLowerCase() === 'linkedin'
    )?.url,
    cvPdf: cvPdf ? { id: cvPdf.id, name: cvPdf.name } : null,
    consent: profile?.consent,
  }
}
