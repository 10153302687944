import React from 'react'
import { useGetProfileQuery } from '@local/Api/profileApi'
import { ScrollToProfile } from '@local/Features/ProfilePage'
import { Box, BoxProps, CircularProgress, Grid2 as Grid } from '@mui/material'
import { useAuthentication } from '@trr/app-shell-data'

import { ActivityPuff } from './ActivityPuff'
import { JobMatchPuff } from './JobMatchPuff'

export const ProfilePuffs = ({ ...props }: BoxProps) => {
  const { sub } = useAuthentication()
  const { isLoading } = useGetProfileQuery(sub)

  if (isLoading) return <CircularProgress />

  return (
    <Box {...props}>
      <Grid container flexGrow={1} spacing={2} columns={{ xs: 1, sm: 4 }}>
        <Grid size={{ xs: 1, sm: 4 }} display={{ md: 'none' }}>
          <ScrollToProfile />
        </Grid>
        <Grid size={{ xs: 1, sm: 2 }}>
          <JobMatchPuff />
        </Grid>
        <Grid size={{ xs: 1, sm: 2 }}>
          <ActivityPuff />
        </Grid>
      </Grid>
    </Box>
  )
}
