import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import React from 'react'
import { WizardStepPaths } from '@local/Router/routes'
import { useGetWizardStep } from '@local/Utils/Hooks/wizard'
import { useAuthentication } from '@trr/app-shell-data'
import { useGetProfileQuery } from '@local/Api/profileApi'

import ProfileChipBlock from '../ProfileChipBlock/ProfileChipBlock'
import ProfileSection from '../ProfileSection/ProfileSection'

const JobTitlesBlock: React.FC = () => {
  const { getWizardUrlStep } = useGetWizardStep()
  const { sub } = useAuthentication()
  const { data: profile } = useGetProfileQuery(sub)

  const { title, linkLabel } = usePickEpiContent().profile.jobtitles

  const jobtitlesChipList = profile?.jobTitles.map((jobtitle) => ({
    id: jobtitle.id,
    name: jobtitle.name,
  }))

  return (
    <ProfileSection
      title={title}
      changeButtonProps={{
        title: linkLabel,
        href: getWizardUrlStep(WizardStepPaths.Jobtitlar, true),
      }}
      data-testid="jobtitle-block"
    >
      <ProfileChipBlock chips={jobtitlesChipList} />
    </ProfileSection>
  )
}

export default JobTitlesBlock
