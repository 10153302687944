import React from 'react'
import { usePatchProfileMutation } from '@local/Api/patchProfileApi'
import { InputGroup } from '@local/Components/InputGroup/InputGroup'
import { ProfileWizardStep } from '@local/Components/ProfileWizardStep/ProfileWizardStep'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { useWizardNavigation } from '@local/Utils/Hooks/wizard'
import { ExtentsOfEmployment, FormsOfEmployment } from '@local/Types'
import {
  generateErrorMessage,
  isEditingOrUpdating,
} from '@local/Utils/Helpers/form.helpers'
import { PatchOps } from '@local/Types/patchTypes'
import { useWizard } from '@trr/wizard-library'
import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik'
import { isEmpty } from 'ramda'
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@mui/material'
import { useAuthentication } from '@trr/app-shell-data'
import { useNavigationGtmTracker } from '@local/Utils/Hooks/gtm'

import { JobTypeStepFormValues, JobTypeFormTypes } from '../../Types/formTypes'

export const JobTypePage: React.FC = () => {
  const { stepCount, activeStep } = useWizard()
  const { goToNextWizardStep, goToPreviousWizardStep, saveAndExit } =
    useWizardNavigation()
  const { stepGuide, jobType, jobPartOrFullTime } =
    usePickEpiContent().wizardJobType
  const [patchProfile] = usePatchProfileMutation()
  const { sub } = useAuthentication()
  const { trackInvalidFormSubmit } = useNavigationGtmTracker()

  const { values, setTouched, setFieldValue, validateForm, touched, errors } =
    useFormikContext<JobTypeStepFormValues>()
  const shouldGoBack = isEditingOrUpdating()

  const handlePatchAndNext = () => {
    void patchProfile({
      body: [
        {
          op: PatchOps.Replace,
          path: 'additionalInformation/extentsOfEmployment',
          value: values.extentsOfEmployment,
        },
        {
          op: PatchOps.Replace,
          path: 'additionalInformation/formsOfEmployment',
          value: values.formsOfEmployment,
        },
        {
          op: PatchOps.Replace,
          path: 'completedWizard',
          value: true,
        },
      ],
      clientId: sub,
    })
      .unwrap()
      .then(() => {
        if (shouldGoBack) {
          saveAndExit()
        } else {
          goToNextWizardStep()
        }
      })
  }

  const handleNext = () => {
    validateForm()
      .then((validationErrors) => {
        if (!isEmpty(validationErrors)) {
          void setTouched(
            setNestedObjectValues<FormikTouched<JobTypeStepFormValues>>(
              validationErrors,
              true
            )
          )
          trackInvalidFormSubmit()
        } else {
          handlePatchAndNext()
        }
      })
      .catch((e) => console.error(e))
  }

  const onChangeFormsOfEmployment = (newForm: FormsOfEmployment) => {
    if (!values.formsOfEmployment.includes(newForm)) {
      void setFieldValue(JobTypeFormTypes.FormsOfEmployment, [
        newForm,
        ...values.formsOfEmployment,
      ])
    } else {
      void setFieldValue(
        JobTypeFormTypes.FormsOfEmployment,
        values.formsOfEmployment.filter((form) => form !== newForm)
      )
    }
  }

  const onChangeExtentsOfEmployment = (newExtent: ExtentsOfEmployment) => {
    if (!values.extentsOfEmployment.includes(newExtent)) {
      void setFieldValue(JobTypeFormTypes.ExtentsOfEmployment, [
        newExtent,
        ...values.extentsOfEmployment,
      ])
    } else {
      void setFieldValue(
        JobTypeFormTypes.ExtentsOfEmployment,
        values.extentsOfEmployment.filter((extent) => extent !== newExtent)
      )
    }
  }

  return (
    <ProfileWizardStep
      progressBar={{ max: stepCount, current: activeStep + 1 }}
      stepInfo={{
        currentStepLabel: stepGuide.currentStepTitle,
        nextStepLabel: stepGuide.nextStepTitle,
      }}
      nextButton={{ onClick: handleNext }}
      prevButton={{ onClick: goToPreviousWizardStep }}
    >
      <Typography variant="body1" fontWeight={'bold'} gutterBottom>
        {jobType.heading}
      </Typography>
      <InputGroup mb={2}>
        <FormControlLabel
          label={jobType.tillsvidareOption}
          control={
            <Checkbox
              name={JobTypeFormTypes.FormsOfEmployment}
              checked={values.formsOfEmployment.includes(
                FormsOfEmployment.Employee
              )}
              data-testid="employee-checkbox"
              onChange={() =>
                onChangeFormsOfEmployment(FormsOfEmployment.Employee)
              }
            />
          }
        />
        <FormControlLabel
          label={jobType.tidsbegransadOption}
          control={
            <Checkbox
              name={JobTypeFormTypes.FormsOfEmployment}
              checked={values.formsOfEmployment.includes(
                FormsOfEmployment.TemporaryEmployee
              )}
              data-testid="temporary-employee-checkbox"
              onChange={() =>
                onChangeFormsOfEmployment(FormsOfEmployment.TemporaryEmployee)
              }
            />
          }
        />
        <FormControlLabel
          label={jobType.konsultaSomAnstalldOption}
          control={
            <Checkbox
              name={JobTypeFormTypes.FormsOfEmployment}
              checked={values.formsOfEmployment.includes(
                FormsOfEmployment.Consultant
              )}
              data-testid="consultant-checkbox"
              onChange={() =>
                onChangeFormsOfEmployment(FormsOfEmployment.Consultant)
              }
            />
          }
        />
        <FormControlLabel
          label={jobType.konsultaSomForetagOption}
          control={
            <Checkbox
              name={JobTypeFormTypes.FormsOfEmployment}
              checked={values.formsOfEmployment.includes(
                FormsOfEmployment.Freelance
              )}
              onChange={() =>
                onChangeFormsOfEmployment(FormsOfEmployment.Freelance)
              }
              data-testid="freelance-checkbox"
            />
          }
        />
        <FormHelperText error data-testid="formsofemployment-error">
          {generateErrorMessage({
            touched: touched.formsOfEmployment !== undefined,
            errorMsg: errors.formsOfEmployment as string,
          })}
        </FormHelperText>
      </InputGroup>

      <Typography variant="body1" fontWeight={'bold'} gutterBottom>
        {jobPartOrFullTime.heading}
      </Typography>

      <InputGroup>
        <FormControlLabel
          label={jobPartOrFullTime.heltidOption}
          control={
            <Checkbox
              name={JobTypeFormTypes.ExtentsOfEmployment}
              checked={values.extentsOfEmployment.includes(
                ExtentsOfEmployment.FullTime
              )}
              data-testid="fulltime-checkbox"
              onChange={() =>
                onChangeExtentsOfEmployment(ExtentsOfEmployment.FullTime)
              }
            />
          }
        />
        <FormControlLabel
          label={jobPartOrFullTime.deltidOption}
          control={
            <Checkbox
              name={JobTypeFormTypes.ExtentsOfEmployment}
              checked={values.extentsOfEmployment.includes(
                ExtentsOfEmployment.PartTime
              )}
              onChange={() =>
                onChangeExtentsOfEmployment(ExtentsOfEmployment.PartTime)
              }
              data-testid="parttime-checkbox"
            />
          }
        />
        <FormHelperText error data-testid="extentsofemployment-error">
          {generateErrorMessage({
            touched: touched.extentsOfEmployment !== undefined,
            errorMsg: errors.extentsOfEmployment as string,
          })}
        </FormHelperText>
      </InputGroup>
    </ProfileWizardStep>
  )
}
