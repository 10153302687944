import { object, array, string, AnySchema, mixed } from 'yup'
import {
  LocationInformation,
  LocationInformationTypes,
  OtherPreferences,
} from '@local/Types'

import { JobLocationStepFormValues } from '../../Types/formTypes'
import { JobLocationStepContent } from '../../Types/content'

export const jobLocationStepValidationSchema = (
  content: JobLocationStepContent
): AnySchema<JobLocationStepFormValues> =>
  object().shape({
    locations: array()
      .of(
        object().shape({
          id: string().required(),
          name: string().required(),
          type: mixed<LocationInformationTypes>().required(),
          locatedIn: mixed<LocationInformation>(),
        })
      )
      .min(1, content.locationPicker.warning),
    otherPreferences: array(
      mixed<OtherPreferences>().oneOf(Object.values(OtherPreferences))
    ).ensure(),
    foreignLocations: array().of(
      object().shape({
        id: string().required(),
        name: string().required(),
        type: mixed<LocationInformationTypes>().required(),
        locatedIn: mixed<LocationInformation>(),
      })
    ),
  })
