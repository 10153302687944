import React, { useId } from 'react'
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  SvgIconProps,
} from '@mui/material'
import { ArrowForward } from '@mui/icons-material'

interface ProfilePuffProps {
  to?: string
  Icon: React.FC<SvgIconProps>
  title: React.ReactNode
  description: React.ReactNode
  testSelector?: string
}

export const ProfilePuff: React.FC<ProfilePuffProps> = ({
  to,
  Icon,
  title,
  description,
  testSelector,
}) => {
  const labelId = useId()
  const descriptionId = useId()
  return (
    <ListItem divider>
      <ListItemButton
        href={to}
        aria-labelledby={labelId}
        aria-describedby={descriptionId}
        disabled={!to}
        data-testid={testSelector}
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: (t) => t.palette.surface?.purple }}>
            <Icon color="primary" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={title}
          secondary={description}
          primaryTypographyProps={{ variant: 'h6', id: labelId }}
          secondaryTypographyProps={{ id: descriptionId }}
        />
        <ArrowForward />
      </ListItemButton>
    </ListItem>
  )
}
