import React, { useId } from 'react'
import { ListItem, ListItemText, Switch } from '@mui/material'

interface ConsentSwitchProps {
  checked: boolean
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
  label: string
  description: string
}

export const ConsentSwitch: React.FC<ConsentSwitchProps> = ({
  checked = false,
  onChange,
  label,
  description,
}) => {
  const titleId = useId()
  const subtitleId = useId()

  return (
    <ListItem>
      <ListItemText
        primary={label}
        secondary={description}
        sx={{ mr: 2 }}
        primaryTypographyProps={{ id: titleId }}
        secondaryTypographyProps={{ id: subtitleId }}
      />
      <Switch
        checked={checked}
        onChange={onChange}
        aria-labelledby={titleId}
        aria-describedby={subtitleId}
        data-testid="consent-switch"
      />
    </ListItem>
  )
}
