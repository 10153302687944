import getConfig from '@local/Utils/getConfig'
import { Profile, SocialMedia, SocialMediaTypes } from '@local/Types'
import { baseApi } from '@local/Api/baseApi'

const { PUBLIC_WEB_API_URL } = getConfig()

export const socialMediaApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getSocialMedia: builder.query<SocialMedia[], string>({
      query: (clientId) => ({
        url: `/client-match/profiles/${clientId}`,
      }),
      providesTags: ['SocialMedia'],
      transformResponse: (res: Profile) =>
        res?.personalInformation?.socialMedia,
    }),
    updateSocialMedia: builder.mutation<
      void,
      { url: string; type: SocialMediaTypes; clientId: string }
    >({
      query: ({ url, type }) => ({
        url: `${PUBLIC_WEB_API_URL}/user/social-media`,
        method: 'PUT',
        body: url ? [{ url, type } as SocialMedia] : [],
      }),
      onQueryStarted: async (
        { url, type, clientId },
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          socialMediaApi.util.updateQueryData(
            'getSocialMedia',
            clientId,
            (data) =>
              url
                ? [
                    ...data.filter((item) => item?.type !== type),
                    {
                      url,
                      type,
                    },
                  ]
                : data.filter((item) => item?.type !== type)
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
  }),
})

export const { useUpdateSocialMediaMutation, useGetSocialMediaQuery } =
  socialMediaApi
