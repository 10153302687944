import { baseApi } from '@local/Api/baseApi'
import { JobTitle, LocationInformation, Profile } from '@local/Types'

export interface MatchingProfileSuggestions {
  competences: string[]
  locations: LocationInformation[]
  professions: JobTitle[]
  totalCount: number
}

const validLocation = (locations: LocationInformation) =>
  locations.id && locations.type && locations.name

const validProfession = (professions: JobTitle) =>
  professions.id && professions.name

export const profileApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getProfile: builder.query<Profile, string>({
      query: (clientId) => `/client-match/profiles/${clientId}`,
      providesTags: ['Profile'],
    }),
    getProfileSuggestions: builder.query<MatchingProfileSuggestions, string>({
      query: (clientId) => `/client-match/profiles/${clientId}/suggestions`,
      transformResponse: (res: MatchingProfileSuggestions) => ({
        competences: res.competences,
        locations: res.locations.filter(validLocation),
        professions: res.professions.filter(validProfession),
        totalCount:
          (res?.competences.length ?? 0) +
          (res?.locations.length ?? 0) +
          (res?.professions.length ?? 0),
      }),
      providesTags: ['Suggestions'],
    }),
  }),
})

export const { useGetProfileQuery, useGetProfileSuggestionsQuery } = profileApi
