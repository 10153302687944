import React from 'react'
import { useGetProfileQuery } from '@local/Api/profileApi'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { Table, TableBody, TableCell, TableRow, styled } from '@mui/material'
import { useAuthentication } from '@trr/app-shell-data'

import ProfileSection from '../ProfileSection/ProfileSection'

const StyledCell = styled(TableCell)({ paddingLeft: 0, paddingRight: 0 })

const ContactInformationBlock: React.FC = () => {
  const { sub } = useAuthentication()
  const { data, isLoading } = useGetProfileQuery(sub)
  const { title, phone, email } = usePickEpiContent().profile.contactInfo
  const { url, linkLabel } = usePickEpiContent().profile.editContactInfo

  if (isLoading) {
    return null
  }

  return (
    <ProfileSection
      title={title}
      changeButtonProps={{
        'data-testid': 'change-information-button',
        'aria-label': linkLabel,
        href: url,
      }}
    >
      <Table>
        <TableBody>
          {data?.personalInformation?.email && (
            <TableRow>
              <StyledCell>{email}</StyledCell>
              <TableCell>{data?.personalInformation?.email}</TableCell>
            </TableRow>
          )}
          {data?.personalInformation?.mobilePhone && (
            <TableRow>
              <StyledCell>{phone}</StyledCell>
              <TableCell>{data?.personalInformation?.mobilePhone}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </ProfileSection>
  )
}

export default ContactInformationBlock
