/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import sv from 'date-fns/locale/sv'

type trrDateType = (date?: Date | string | number) => Date

type baseTrrFormatType = (formatStr: string) => (date?: Date | string) => string

const stockholmTimeZone = 'Europe/Stockholm'

export const trrDate: trrDateType = (date) =>
  utcToZonedTime(date ? date : new Date(), stockholmTimeZone)

export const trrDateStrict: trrDateType = (date) =>
  date ? utcToZonedTime(date, stockholmTimeZone) : null

export const trrDateUTC: trrDateType = (date) =>
  zonedTimeToUtc(date, stockholmTimeZone)

export const baseTrrFormat: baseTrrFormatType =
  (formatStr: string) =>
  (date: Date | string = new Date()) =>
    format(trrDate(date), formatStr, {
      timeZone: stockholmTimeZone,
      locale: sv,
    })

export const trrFormat = baseTrrFormat('yyyy-MM-dd')
export const trrFormatOccasionDate = baseTrrFormat('d MMM yyyy')
export const trrFormatTime = baseTrrFormat('H:mm')
export const trrFormatMeetingDateSummary = baseTrrFormat('EEEE d MMMM yyyy')
export const trrFormatMeetingTimeSummary = baseTrrFormat('HH:mm')

export const isoDateToUnixTimestamp = (date: string): number => {
  const dateObj = new Date(date)
  return dateObj.getTime()
}
