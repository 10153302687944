import React from 'react'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { generateErrorMessage } from '@local/Utils/Helpers/form.helpers'
import { useFormikContext } from 'formik'
import { Box, BoxProps } from '@mui/material'
import { linkedInRegEx } from '@local/Utils/Helpers/regexes'
import { LinkTextField } from '@local/Components/LinkTextField/LinkTextField'

import { ExperienceStepFormValues } from '../../Types/experienceStepFormTypes'

export const LinkedInBlock = ({ ...props }: BoxProps) => {
  const { values, handleBlur, handleChange, errors, touched } =
    useFormikContext<ExperienceStepFormValues>()
  const { placeholder, label } = usePickEpiContent().wizardExperience.linkedIn
  const hasErrors = errors?.linkedIn?.length > 0
  const isTouched = touched.linkedIn !== undefined

  const handleClear = () => {
    handleChange({ target: { name: 'linkedIn', value: '' } })
  }

  return (
    <Box {...props}>
      <LinkTextField
        variant="outlined"
        linkTestId="linkedin-external-link"
        buttonAriaLabel="öppna linkedin-profil i ny flik"
        valueRegex={linkedInRegEx}
        hasErrors={hasErrors}
        placeholder={placeholder}
        label={label}
        value={values.linkedIn}
        name="linkedIn"
        onChange={handleChange}
        onBlur={handleBlur}
        onClear={handleClear}
        fullWidth
        error={hasErrors && isTouched}
        disableTracking
        helperText={generateErrorMessage({
          touched: isTouched,
          errorMsg: errors.linkedIn,
        })}
        FormHelperTextProps={{ id: 'linkedin-input-error' }}
        inputProps={{ 'data-testid': 'linkedin-input' }}
      />
    </Box>
  )
}
