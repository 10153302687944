import { isEqualJobtitle } from '@local/Features/WizardSteps/JobtitlesStep/Helpers/jobtitleHelpers'
import { JobtitleStepFormValues } from '@local/Features/WizardSteps/JobtitlesStep/Types/formTypes'
import { JobTitle } from '@local/Types'
import { useFormikContext } from 'formik'

export const useJobtitlesForm = () => {
  const {
    values: { jobtitles },
    setFieldValue,
    setErrors,
    touched,
    errors,
  } = useFormikContext<JobtitleStepFormValues>()

  const addJobtitle = (jobtitle: JobTitle) => {
    void setFieldValue('jobtitles', [...jobtitles, jobtitle], false)
  }

  const toggleJobtitle = (jobtitle: JobTitle) =>
    (jobtitles.some((j) => isEqualJobtitle(j, jobtitle))
      ? removeJobtitle
      : addJobtitle)(jobtitle)

  const removeJobtitle = (jobtitle: JobTitle) => {
    const newCompetences = jobtitles.filter(
      (j) => !isEqualJobtitle(j, jobtitle)
    )
    void setFieldValue('jobtitles', newCompetences, false)
  }

  const clearJobtitles = () => {
    void setFieldValue('jobtitles', [], false)
  }

  return {
    jobtitles,
    setErrors,
    touched,
    errors,
    toggleJobtitle,
    addJobtitle,
    removeJobtitle,
    clearJobtitles,
  }
}
