import getConfig from '@local/Utils/getConfig'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import React from 'react'

const { MEDIA_URL } = getConfig()

interface ArticleImageCardProps {
  image: string
  imageAlt?: string
  title: string
  description: string
  linkText?: string
  link?: string
  cardSx?: SxProps<Theme>
  cardImageSx?: SxProps<Theme>
}
/**
 * This is only meant to be used with the specific PhotoTextImage block in EPI.
 */

const ArticleImageCard: React.FC<ArticleImageCardProps> = ({
  image,
  imageAlt,
  title,
  description,
  linkText,
  link,
  cardSx,
  cardImageSx,
}) => {
  const sanatizedDescription = description.replace(/<[^>]*>?/gm, '')

  return (
    <Card sx={{ marginTop: '40px', ...cardSx }}>
      <CardMedia
        image={`${MEDIA_URL}${image}?width=750`}
        title={imageAlt}
        sx={{ height: 225, ...cardImageSx }}
      />
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: sanatizedDescription }}
        />
      </CardContent>
      {link && (
        <CardActions>
          <Button variant="text" href={link} size="small">
            {linkText}
          </Button>
        </CardActions>
      )}
    </Card>
  )
}

export default ArticleImageCard
