import React from 'react'
import { withFormik } from 'formik'
import { CompetenceStepComponent } from '@local/Features/WizardSteps/CompetenceStep/Components/CompetenceStepContent'

import {
  CompetenceStepFormProps,
  CompetenceStepFormValues,
} from '../Types/formTypes'

import { competenceStepValidationSchema } from './CompetenceStepForm.schema'

const CompetenceStepForm: React.FC = () => <CompetenceStepComponent />

export const CompetenceStepFormik = withFormik<
  CompetenceStepFormProps,
  CompetenceStepFormValues
>({
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: ({ profileCompetences }) => ({
    competences: profileCompetences,
  }),
  validationSchema: ({ content }: CompetenceStepFormProps) =>
    competenceStepValidationSchema(content),
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  displayName: 'CompetenceStepForm',
})(CompetenceStepForm)
