import { usePatchProfileMutation } from '@local/Api/patchProfileApi'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { useWizardNavigation } from '@local/Utils/Hooks/wizard'
import { OtherPreferences } from '@local/Types'
import { PatchOps } from '@local/Types/patchTypes'
import { useWizard } from '@trr/wizard-library'
import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik'
import React from 'react'
import { isEmpty } from 'lodash'
import { ProfileWizardStep } from '@local/Components/ProfileWizardStep/ProfileWizardStep'
import { isEditingOrUpdating } from '@local/Utils/Helpers/form.helpers'
import { Stack } from '@mui/material'
import { useFeatureFlag } from '@local/Utils/Hooks'
import { useAuthentication } from '@trr/app-shell-data'
import { useNavigationGtmTracker } from '@local/Utils/Hooks/gtm'
import { LocationSuggestions } from '@local/Features/WizardSteps/JobLocationStep/Components/LocationSuggestions/LocationSuggestions'
import { WorkplaceTypeForm } from '@local/Features/WizardSteps/JobLocationStep/Components/JobLocationStep/WorkplaceTypeForm/WorkplaceTypeForm'
import { LocationsForm } from '@local/Features/WizardSteps/JobLocationStep/Components/JobLocationStep/LocationsForm/LocationsForm'

import { JobLocationStepFormValues } from '../../Types/formTypes'

export const JobLocationStep: React.FC = () => {
  const { stepCount, activeStep } = useWizard()
  const { goToNextWizardStep, goToPreviousWizardStep, saveAndExit } =
    useWizardNavigation()
  const [patchProfile] = usePatchProfileMutation()
  const shouldGoBack = isEditingOrUpdating()
  const { trackInvalidFormSubmit } = useNavigationGtmTracker()
  const { stepGuide } = usePickEpiContent().wizardVarOchHur
  const { sub } = useAuthentication()

  const { isSuggestionsBlockEnabled } = useFeatureFlag()

  const { values, setTouched, validateForm } =
    useFormikContext<JobLocationStepFormValues>()

  const handlePrevious = () => {
    goToPreviousWizardStep()
  }

  const handlePatchAndNext = () => {
    const otherPreferences: OtherPreferences[] = values.otherPreferences

    void patchProfile({
      body: [
        {
          op: PatchOps.Replace,
          path: 'locationinformations',
          value: [...values.locations, ...values.foreignLocations],
        },
        {
          op: PatchOps.Replace,
          path: 'additionalInformation/otherPreferences',
          value: otherPreferences,
        },
      ],
      clientId: sub,
    })
      .unwrap()
      .then(() => {
        if (shouldGoBack) {
          saveAndExit()
        } else {
          goToNextWizardStep()
        }
      })
  }

  const handleNext = () => {
    validateForm()
      .then((validationErrors) => {
        if (!isEmpty(validationErrors)) {
          void setTouched(
            setNestedObjectValues<FormikTouched<JobLocationStepFormValues>>(
              validationErrors,
              true
            )
          )
          trackInvalidFormSubmit()
        } else {
          handlePatchAndNext()
        }
      })
      .catch((e) => console.error(e))
  }

  return (
    <ProfileWizardStep
      progressBar={{ max: stepCount, current: activeStep + 1 }}
      stepInfo={{
        currentStepLabel: stepGuide.currentStepTitle,
        nextStepLabel: stepGuide.nextStepTitle,
      }}
      nextButton={{ onClick: handleNext }}
      prevButton={{ onClick: handlePrevious }}
    >
      <Stack spacing={5}>
        {isSuggestionsBlockEnabled && <LocationSuggestions />}
        <LocationsForm />
        <WorkplaceTypeForm />
      </Stack>
    </ProfileWizardStep>
  )
}
