import React from 'react'
import { withFormik } from 'formik'
import { OtherPreferences } from '@local/Types'

import { filterCountries } from '../../Utils/formHelper'
import {
  JobLocationStepFormProps,
  JobLocationStepFormValues,
} from '../../Types/formTypes'
import { JobLocationStep } from '../JobLocationStep/JobLocationStep'

import { jobLocationStepValidationSchema } from './JobLocationStepForm.schema'

const JobLocationStepForm: React.FC = () => <JobLocationStep />

export const JobLocationStepFormik = withFormik<
  JobLocationStepFormProps,
  JobLocationStepFormValues
>({
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: ({ varOchHur }) => ({
    locations: filterCountries(varOchHur?.locationInformations),
    foreignLocations: filterCountries(varOchHur?.locationInformations, true),
    otherPreferences: varOchHur.additionalInformation
      .otherPreferences as OtherPreferences[],
  }),
  validationSchema: ({ content }: JobLocationStepFormProps) =>
    jobLocationStepValidationSchema(content),
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  displayName: 'jobLocationStepForm',
})(JobLocationStepForm)
